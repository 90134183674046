import {Injectable} from '@angular/core';
import {
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {environment} from "../../../environments/environment";
import {register} from "ts-node";


@Injectable()
export class FakeBackendInterceptor implements HttpInterceptor {

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const {url, method, headers, body} = request;
    // wrap in delayed observable to simulate server api call
    return of(null).pipe(mergeMap(convertUrl));

    function convertUrl() {
      const parameterIndex: number = url.indexOf('api');
      const paytr: number = url.indexOf('.paytr.com');
      if (paytr > -1) {
        const path1 = request.clone({
          url: request.url
        });
        return next.handle(path1);
      }


      const parameters = parameterIndex === -1
        ? url
        : url.slice(parameterIndex);
      const baseUrl = environment.localApiUrl;
      const path = request.clone({
        //url: request.url.replace(url,  parameters)
        url: baseUrl + parameters
      });

      return next.handle(path);
    }


    function handleRoute() {
      switch (true) {
        case url.endsWith('api/client/register') && method === 'POST':
          return register();
        case url.endsWith('api/client/companyList') && method === 'POST':
          return companyList();
        case url.endsWith('api/instance/instanceList') && method === 'GET':
          return instanceList();
        case url.endsWith('api/admin/adminList') && method === 'GET':
          return adminsList();
          case url.endsWith('api/admin/delete') && method === 'POST':
          return adminDelete();
        case url.endsWith('api/admin/add') && method === 'POST':
          return addAdmin();
        case url.endsWith('api/admin/filter') && method === 'POST':
          return filterAdmin();
        case url.endsWith('api/admin/update') && method === 'POST':
          return updateAdmin();
        case url.endsWith('api/order/orderList') && method === 'GET':
          return ordersList();
        case url.endsWith('api/order/filter') && method === 'POST':
          return ordersFilter();
          case url.endsWith('api/page/pageList') && method === 'GET':
            return pagesList();
        case url.endsWith('api/client/companyAnalysisList') && method === 'GET':
          return companyAnalysisList();
        case url.endsWith('api/client/getCompanyDetail') && method === 'POST':
          return companyDetails();
        case url.endsWith('api/client/updateCompany') && method === 'POST':
          return updateCompany();
        case url.endsWith('api/client/filterCompanyList') && method === 'POST':
          return filterCompanyList();
        case url.endsWith('api/instance/createInstance') && method === 'POST':
          return addInstance();
         case url.endsWith('api/instance/update') && method === 'POST':
                  return updateInstance();
        case url.endsWith('api/instance/delete') && method === 'POST':
          return deleteInstance();
        case url.endsWith('api/client/login') && method === 'POST':
          return login();



        default:
          // pass through any requests not handled above
          // return convertUrl(url);
      }
    }


    // function convertUrl(fromUrl) {
    //   console.log("convertURL");
    //   const parameterIndex: number = fromUrl.indexOf('/v1');
    //   const parameters = fromUrl.slice(parameterIndex + 2);
    //   const productsUrl = request.clone({
    //     url: request.url.replace(fromUrl, environment.localApiUrl + 'api/client/login')
    //   });
    //   return next.handle(productsUrl);
    // }

    // route functions
    function authenticate() {
      const {username, password} = body;
     /* const user = user.find(
        (x) => x.username === username && x.password === password
      );
      if (!user) {
        return error('Username or password is incorrect');
      }
      return ok({
        id: user.id,
        username: user.username,
        firstName: user.firstName,
        lastName: user.lastName,
        token: user.token
      });*/
      const parameterIndex: number = url.indexOf('api');
      const parameters = url.slice(parameterIndex + 5);
      const authUrl = request.clone({
        url: request.url.replace(url, environment.localApiUrl + "api/client/login")
      });
      return next.handle(authUrl);
    }

    function register() {
      const parameterIndex: number = url.indexOf('api');
      const parameters = url.slice(parameterIndex + 5);
      const registerUrl = request.clone({
        url: request.url.replace(url, environment.localApiUrl + "/client/register")
      });
      return next.handle(registerUrl);
    }
    function login() {
      const parameterIndex: number = url.indexOf('api');
      const parameters = url.slice(parameterIndex + 5);
      const registerUrl = request.clone({
        url: request.url.replace(url, environment.localApiUrl + "/client/login")
      });
      return next.handle(registerUrl);
    }
    function companyList() {
      /*const {userId, companyId} = body;*/
      const parameterIndex: number = url.indexOf('api');
      const parameters = url.slice(parameterIndex + 5);
      const selectCompanyUrl = request.clone({
        url: request.url.replace(url, environment.localApiUrl + "api/client/companyList")
      });
      return next.handle(selectCompanyUrl);
    }
    function instanceList() {
      /*const {userId, companyId} = body;*/
      const parameterIndex: number = url.indexOf('api');
      const parameters = url.slice(parameterIndex + 5);
      const selectCompanyUrl = request.clone({
        url: request.url.replace(url, environment.localApiUrl + "api/instance/instanceList")
      });
      return next.handle(selectCompanyUrl);
    }
    function addInstance() {
      /*const {userId, companyId} = body;*/
      const parameterIndex: number = url.indexOf('api');
      const parameters = url.slice(parameterIndex + 5);
      const selectCompanyUrl = request.clone({
        url: request.url.replace(url, environment.localApiUrl + "api/instance/createInstance")
      });
      return next.handle(selectCompanyUrl);
    }
    function updateInstance() {
      /*const {userId, companyId} = body;*/
      const parameterIndex: number = url.indexOf('api');
      const parameters = url.slice(parameterIndex + 5);
      const selectCompanyUrl = request.clone({
        url: request.url.replace(url, environment.localApiUrl + "api/instance/update")
      });
      return next.handle(selectCompanyUrl);
    }

    function deleteInstance() {
      /*const {userId, companyId} = body;*/
      const parameterIndex: number = url.indexOf('api');
      const parameters = url.slice(parameterIndex + 5);
      const selectCompanyUrl = request.clone({
        url: request.url.replace(url, environment.localApiUrl + "api/instance/delete")
      });
      return next.handle(selectCompanyUrl);
    }

    function adminsList() {
      /*const {userId, companyId} = body;*/
      const parameterIndex: number = url.indexOf('api');
      const parameters = url.slice(parameterIndex + 5);
      const selectCompanyUrl = request.clone({
        url: request.url.replace(url, environment.localApiUrl + "api/admin/adminList")
      });
      return next.handle(selectCompanyUrl);
    }
    function adminDelete() {
      /*const {userId, companyId} = body;*/
      const parameterIndex: number = url.indexOf('api');
      const parameters = url.slice(parameterIndex + 5);
      const selectCompanyUrl = request.clone({
        url: request.url.replace(url, environment.localApiUrl + "api/admin/delete")
      });
      return next.handle(selectCompanyUrl);
    }
    function ordersList() {
      /*const {userId, companyId} = body;*/
      const parameterIndex: number = url.indexOf('api');
      const parameters = url.slice(parameterIndex + 5);
      const selectCompanyUrl = request.clone({
        url: request.url.replace(url, environment.localApiUrl + "api/order/orderList")
      });
      return next.handle(selectCompanyUrl);
    }

    function ordersFilter() {
      /*const {userId, companyId} = body;*/
      const parameterIndex: number = url.indexOf('api');
      const parameters = url.slice(parameterIndex + 5);
      const selectCompanyUrl = request.clone({
        url: request.url.replace(url, environment.localApiUrl + "api/order/filter")
      });
      return next.handle(selectCompanyUrl);
    }
    function pagesList() {
      /*const {userId, companyId} = body;*/
      const parameterIndex: number = url.indexOf('api');
      const parameters = url.slice(parameterIndex + 5);
      const selectCompanyUrl = request.clone({
        url: request.url.replace(url, environment.localApiUrl + "api/page/pageList")
      });
      return next.handle(selectCompanyUrl);
    }
    function filterCompanyList() {
      const parameterIndex: number = url.indexOf('api');
      const parameters = url.slice(parameterIndex + 5);
      const selectCompanyUrl = request.clone({
        url: request.url.replace(url, environment.localApiUrl + "api/client/filterCompanyList")
      });
      return next.handle(selectCompanyUrl);
    }
    function companyAnalysisList() {
      /*const {userId, companyId} = body;*/
      const parameterIndex: number = url.indexOf('api');
      const parameters = url.slice(parameterIndex + 5);
      const selectCompanyUrl = request.clone({
        url: request.url.replace(url, environment.localApiUrl + "api/client/companyAnalysisList")
      });
      return next.handle(selectCompanyUrl);
    }
    function updateCompany() {
      console.log('sa');
      const parameterIndex: number = url.indexOf('api');
      const parameters = url.slice(parameterIndex + 5);
      const selectCompanyUrl = request.clone({
        url: request.url.replace(url, environment.localApiUrl + "api/client/updateCompany")
      });
      return next.handle(selectCompanyUrl);
    }
    function addAdmin() {
      const parameterIndex: number = url.indexOf('api');
      const parameters = url.slice(parameterIndex + 5);
      const selectCompanyUrl = request.clone({
        url: request.url.replace(url, environment.localApiUrl + "api/admin/add")
      });
      return next.handle(selectCompanyUrl);
    }
    function filterAdmin() {
      const parameterIndex: number = url.indexOf('api');
      const parameters = url.slice(parameterIndex + 5);
      const selectCompanyUrl = request.clone({
        url: request.url.replace(url, environment.localApiUrl + "api/admin/filter")
      });
      return next.handle(selectCompanyUrl);
    }

    function updateAdmin() {
      const parameterIndex: number = url.indexOf('api');
      const parameters = url.slice(parameterIndex + 5);
      const selectCompanyUrl = request.clone({
        url: request.url.replace(url, environment.localApiUrl + "api/admin/update")
      });
      return next.handle(selectCompanyUrl);
    }
    function companyDetails() {

    /*  const {userId, companyId} = body;*/
      const parameterIndex: number = url.indexOf('api');
      const parameters = url.slice(parameterIndex + 5);
      const selectCompanyUrl = request.clone({
        url: request.url.replace(url, environment.localApiUrl + "api/client/getCompanyDetail")
      });
      return next.handle(selectCompanyUrl);
    }
    // helper functions

    function ok(body?) {
      return of(new HttpResponse({status: 200, body}));
    }

    function error(message) {
      return throwError({error: {message}});
    }

    function unauthorized() {
      return throwError({status: 401, error: {message: 'Unauthorised'}});
    }

    function isLoggedIn() {
      return headers.get('Authorization') === 'Bearer fake-jwt-token';
    }
  }
}

export let fakeBackendProvider = {
  // use fake backend in place of Http service for backend-less development
  provide: HTTP_INTERCEPTORS,
  useClass: FakeBackendInterceptor,
  multi: true
};
