import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {ColumnMode, DatatableComponent, SelectionType} from '@swimlane/ngx-datatable';
import {MatDialog} from "@angular/material/dialog";
import {CompanyService} from "../../../core/service/company.service";
import {Company} from "../../../core/models/company";
import {MatMenuTrigger} from "@angular/material/menu";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {NgxSpinnerService} from "ngx-spinner";
import * as XLSX from 'xlsx';
import {formatDate} from "@angular/common";
import {PaggingModel} from "../../../core/models/pagging-model";
import {PaggingResponseModel} from "../../../core/models/pagging-response-model";
import {CompanyStatus} from "../../../core/models/company-status";
import {LicenseType} from "../../../core/models/license-type";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {ActivatedRoute, Router} from "@angular/router";
import {CustomerFilterComponent} from "../../../components/customer-management/customer-filter/customer-filter.component";
import {CustomerTableComponent} from "../../../components/customer-management/customer-table/customer-table.component";
import {CompanyFilterModel} from "../../../core/models/company-filter-model";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import {UntypedFormControl} from '@angular/forms';
import {TableColumnVisibility} from "../../../core/models/table-column-visibility";
import {TableTypeEnum} from "../../../components/general-table/enums/table-type-enum";




export interface CompanyData {
  id: number;
  remoteSupportPermission: boolean;
  title: string;
  referenceCode: string;
  fullname: string;
  email: string;
  expireDate: string;
  activation: boolean;
  isActive: boolean;
  remainingTime: string;
  licenseType: LicenseType;
  licenseStatus: CompanyStatus;
}

@Component({
  selector: 'app-customer-management-index',
  templateUrl: './customer-management-index.component.html',
  styleUrls: ['./customer-management-index.component.scss']
})




export class CustomerManagementIndexComponent implements AfterViewInit, OnInit {
  listType = "0";
  toppings = new UntypedFormControl();
  nam = "asfsa";
  toppingList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato', this.nam];
  public get tableTypeEnum(): typeof TableTypeEnum { return TableTypeEnum; }
  visibility: TableColumnVisibility = {
    select: true,
    activation: true,
    email: true,
    expireDate: true,
    fullname: true,
    id: true,
    isActive: true,
    licenseType: true,
    licenseStatus: true,
    referenceCode: true,
    remainingTime: true,
    remoteSupportPermission: true,
    title: true,
  };

  visibilityChange(change: any)
  {
    this.visibility[change] = !this.visibility[change];

  }

  displayedColumns: string[] = ['select','id', 'remoteSupportPermission', 'title', 'referenceCode', 'fullname', 'email', 'expireDate', 'activation', 'isActive', 'remainingTime', 'licenseType', 'licenseStatus'];
  dataSource: MatTableDataSource<CompanyData>;
  @Input() lType: string;
  page = new PaggingResponseModel();
  pageLimit = 50;
  selected = [];
  rows = [];
  temp = [];
  ColumnMode = window.innerWidth > 900
    ? ColumnMode.force
    : ColumnMode.force;
  SelectionType = SelectionType;
  searchInput = "";
  company: any;
  companyId: any;
  companies = [];
  isFiltered = false;
  filter: CompanyFilterModel;
  filterModel: any = null;

  @Input() data: string;
  @ViewChild(DatatableComponent) table: DatatableComponent;
  @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;
  @ViewChild(MatMenuTrigger) process: MatMenuTrigger;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private companyService: CompanyService,
              private dialog: MatDialog,
              private route: ActivatedRoute,
              private notification: NzNotificationService,
              private spinner: NgxSpinnerService,
              private router: Router) {

    /*  this.getPageData(1);
      this.dataSource = new MatTableDataSource(this.temp);*/
  }

  ngOnInit(): void {
    // this.companyService.companyList({
    //   Page: 0,
    //   PageSize: 10
    // }).subscribe((res: any) => {
    //   console.log(res);
    // });

  }

  ngAfterViewInit() {

    // this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  editCompany = (id:any) =>
  {
    console.log(id);
    this.router.navigate(['musteri/musteri-detay/' + id], { queryParams: id});
  }


  getPageData(page) {
    this.companyService.companyList({
      Page: page,
      PageSize: this.pageLimit
    }).subscribe((res: any) => {
      console.log(res);

      this.companies = res.items;
      this.page.Page = res.page;
      this.page.PageSize = res.pageSize;
      this.page.TotalCount = res.totalCount;
      this.mappingCompanies();
    });
  }

  mappingCompanies() {
    this.rows = [];
    this.companies?.map((company) => {
      this.temp.push({
        id: company.id,
        remoteSupportPermission: company.remoteSupportPermission,
        title: company.title,
        referenceCode: company.referenceCode,
        fullname: company.fullname,
        email: company.email,
        expireDate: company.expireDate,
        activation: company.activation,
        isActive: company.isActive,
        remainingTime: company.remainingTime < 0 ? '' : company.remainingTime,
        licenseType: LicenseType[company.licenseType],
        licenseStatus: CompanyStatus[company.companyStatus]
      });
      this.dataSource = new MatTableDataSource(this.temp);
      console.log(this.temp);
    });
    this.spinner.hide();
    console.log(this.rows);
  }

  // editCompany(company) {
  //   this.company = company;
  //   this.companyId = company.id;
  //   console.log(this.company);
  //   this.router.navigate(['musteri/musteri-detay/' + this.companyId], { queryParams: this.companyId });
  // }

//#region
  onSelect({selected}) {
    console.log('Select Event', selected, this.selected);

    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  onActivate(event) {
    /* console.log('Activate Event', event);*/
    /*  this.selectedRow = this.magentyCategories?.filter(x => x.Id === event.row.id)[0];*/
  }

  remove(index) {
    this.selected = [];
    this.rows.splice(index, 1);
  }

  displayCheck(row) {
    return row.name !== 'Ethel Price';
  }
  stopFilter = () => {
    this.getPageData(1);
    this.isFiltered = false;
  }
  excelExport() {
    if (this.selected.length === 0) {
      this.notification.create('error', 'Hata', 'Kategori ya da kategoriler seçilmediği için excel olarak indirilemedi.');
    } else {
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.selected);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Kategori Listesi');
      const fileName = 'kategori_listesi' + formatDate(new Date(), 'dd/MM/yyyy', 'en') + '.xlsx';
      XLSX.writeFile(wb, fileName);
    }
  }

  pdfExport() {
    const prepare = [];
    this.selected.forEach(e => {
      const tempObj = [];
      console.log(e);
      tempObj.push(e.title);
      tempObj.push(e.fullname);
      tempObj.push(e.email);
      tempObj.push(e.licenseType);
      tempObj.push(e.licenseStatus);

      tempObj.push(e.expireDate);

      prepare.push(tempObj);
    });
    console.log(prepare);
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "in",

    });


    autoTable(doc, {
      head: [['Sirket','Yetkili Kisi', 'Email', 'Lisans Tipi', 'Lisans Durumu', 'Bitis Tarihi']],
      body: prepare,
    });
    doc.save('table.pdf');

  }

  /*toggle(col) {
    const isChecked = this.isChecked(col);

    if (isChecked) {
      this.columns = this.columns.filter(c => {
        return c.name !== col.name;
      });
    } else {
      this.columns = [...this.columns, col];
    }
  }*/

  /*isChecked(col): boolean {
    return (
      this.columns.find(c => {
        return c.name === col.name;
      }) !== undefined
    );
  }*/

  searchInputSales() {
    console.log(this.searchInput);
  }

  paginationEvent(){
    this.companyService.filterCompanyList({
      Filter: this.filter,
      IsPaging: true,
      Page: this.paginator.pageIndex,
      PageSize: this.paginator.pageSize
    })
      .subscribe((res: any) => {
        this.companies = res.items;
        this.paginator.length = res.totalCount;
        this.temp = [];
        this.mappingCompanies();
      });
    console.log(this.paginator);

  }

  detailFilter() {
    const dialogRef = this.dialog.open(CustomerFilterComponent, {

      width: '550px'
    });
    dialogRef.afterClosed().subscribe((res: any) => {

      console.log(res);
      if (res !== undefined) {
        // this.isFiltered = true;
        // this.filter = res.filter;
        // this.companies = res.companies;
        // this.temp = [];
        // this.mappingCompanies();
        this.filterModel = res;
      }

    });
  }

  mappingFilter() {
    const filterModel = new CompanyFilterModel();
    filterModel.email = "";
    filterModel.title = "";
    filterModel.isActive = 0;
    filterModel.province = "";
    filterModel.companyStatus = "";
    filterModel.fullname = "";
    filterModel.licenseType = "";
    filterModel.listType = this.listType;
    this.companyService.filterCompanyList({
      Filter: filterModel,
      IsPaging: true,
      Page: 1,
      PageSize: 10
    })
      .subscribe((res: any) => {
        this.companies = res.items;
        this.paginator.length = res.totalCount;
        this.temp = [];
        this.mappingCompanies();
      });
  }

  limitChange(event) {
    this.pageLimit = event.target.value;
  }

  setPage(pageInfo) {
    this.spinner.show();
    this.getPageData(pageInfo.page);
  }


  selectedChange(childSelected: any)
  {
      this.selected = childSelected._selected;
      console.log( this.selected);
  }


  //#endregion


}
