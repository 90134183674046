import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-admins-table',
  templateUrl: './admins-table.component.html',
  styleUrls: ['./admins-table.component.sass']
})
export class AdminsTableComponent implements OnChanges {

  @Input() data: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['id', 'email', 'password', 'AdminType', 'name', 'surname', 'dealerCode', 'phone', 'isActive'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();


  ngOnChanges(changes: SimpleChanges): void {
    this.dataSource = this.data;
  }

  // Filtreleme
  // applyFilter(event: Event) {
  //   const filterValue = (event.target as HTMLInputElement).value;
  //   this.dataSource.filter = filterValue.trim().toLowerCase();

  //   if (this.dataSource.paginator) {
  //     this.dataSource.paginator.firstPage();
  //   }
  // }

}
