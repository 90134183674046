<div class="container-fluid">
    <section class="content">
    <div class="block-header">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <ul class="breadcrumb breadcrumb-style ">
            <li class="breadcrumb-item">
              <h4 class="page-title">Müşteri Yönetimi</h4>
            </li>
            <li class="breadcrumb-item bcrumb-1">
              <a routerLink="/analiz/analiz-anasayfa">
                <i class="fas fa-home"></i> Anasayfa</a>
            </li>
            <li class="breadcrumb-item active">Müşteri Yönetimi</li>
          </ul>
        </div>
      </div>
    </div>

<app-general-table tableType="{{+tableTypeEnum.Customer}}" (edit)="editCompany($event.id)"  ></app-general-table>
<!--    <div class="card mat-elevation-z8">-->


<!--      &lt;!&ndash;-->
<!--                   <div class="row">-->
<!--                   <div class="col-md-6">-->
<!--                       <mat-form-field appearance="outline" style="width: 115px;">-->
<!--                         <mat-label>Listeleme0
 sayısı</mat-label>-->
<!--                         <select (change)="limitChange($event)" [value]="pageLimit" matNativeControl required>-->
<!--                           <option value="5">5</option>-->
<!--                           <option value="10">10</option>-->
<!--                           <option value="25">25</option>-->
<!--                           <option value="50">50</option>-->
<!--                           <option value="100">100</option>-->
<!--                           <option value="200">200</option>-->
<!--                         </select>-->
<!--                       </mat-form-field>-->
<!--                     </div>-->
<!--          </div>-->
<!--          &ndash;&gt;-->


<!--        <div class="row ngxTableHeader">-->

<!--          <ul class="row header-buttons-left ml-0 mb-0">-->
<!--            <li class=" m-l-20"><label _ngcontent-uym-c182="" for="search-input">-->
<!--              <i class="material-icons search-icon">search</i></label>-->
<!--              <input-->
<!--                placeholder="Genel Arama" type="text" aria-label="Search box"-->
<!--                (keyup)="applyFilter($event)"-->
<!--                [(ngModel)]="searchInput"-->
<!--                (ngModelChange)="searchInput.trim() == '' ? searchBtnTooltip.hide() : searchBtnTooltip.show()"-->
<!--                class="browser-default search-field"></li>-->
<!--            <li class=" m-l-20">-->
<!--              <button (click)="applyFilter($event)" [disabled]="searchInput.trim() === ''"-->
<!--                      matTooltip="Aramak için tıklayın veya Enter'a basın." #searchBtnTooltip="matTooltip"-->
<!--                      mat-mini-fab-->
<!--                      color="primary">-->
<!--                <i class="fas fa-search"></i>-->
<!--              </button>-->
<!--            </li>-->
<!--            <li >-->
<!--              <button style="background-color: #f0f8ff00;box-shadow: none !important;"-->
<!--                      (click)="detailFilter()"-->
<!--                      class="mat-focus-indicator mat-button mat-button-base mat-raised-button">-->
<!--                            <span class="mat-button-wrapper">-->
<!--                              <mat-icon role="img"-->
<!--                                        class="detail-filter-icon mat-icon notranslate mat-button-icon is-mat-icon material-icons favorite mat-icon-no-color ng-star-inserted"-->
<!--                                        aria-hidden="true" data-mat-icon-type="font" data-mat-icon-name="favorite">-->
<!--                                tune-->
<!--                              </mat-icon>-->
<!--                            </span>-->
<!--                <span matripple="" class="mat-ripple mat-button-ripple"></span>-->
<!--                <span class="mat-button-focus-overlay"></span>-->
<!--              </button>-->

<!--            </li>-->
<!--            <li >-->
<!--              <button style="background-color: #f0f8ff00;box-shadow: none !important;"-->
<!--                      (click)="stopFilter()"-->
<!--                      *ngIf="isFiltered"-->
<!--                      class="mat-focus-indicator mat-button mat-button-base mat-raised-button bg-danger text-white">-->
<!--                Filtrelemeyi Durdur-->
<!--                <span class="mat-button-wrapper">-->
<!--                              <mat-icon role="img"-->
<!--                                        class="detail-filter-icon mat-icon notranslate mat-button-icon is-mat-icon material-icons favorite mat-icon-no-color ng-star-inserted"-->
<!--                                        aria-hidden="true" data-mat-icon-type="font" data-mat-icon-name="favorite">-->
<!--                                close-->
<!--                              </mat-icon>-->
<!--                            </span>-->
<!--                <span matripple="" class="mat-ripple mat-button-ripple"></span>-->
<!--                <span class="mat-button-focus-overlay"></span>-->
<!--              </button>-->
<!--            </li>-->
<!--          </ul>-->
<!--          <ul class="row ml-auto mt-2">-->
<!--            <li>-->
<!--              &nbsp;-->
<!--              <button (click)="pdfExport()"-->
<!--                mat-stroked-button-->
<!--                class="pdf-icon text-center mr-1"-->
<!--                matTooltip="Pdf olarak indir">-->
<!--                <i class="fas fa-file-pdf"></i>-->
<!--              </button>-->
<!--              <button-->
<!--                (click)="excelExport()"-->
<!--                matTooltip="Excel olarak indir"-->
<!--                mat-stroked-button-->
<!--                class="excel-icon text-center mr-1 text-success">-->
<!--                <i class="fas fa-file-excel"></i>-->
<!--              </button>-->
<!--              &lt;!&ndash;  <button-->
<!--                  [matMenuTriggerFor]="columnMenu"-->
<!--                  mat-stroked-button-->
<!--                  class="column-icon text-center mr-1 text-primary"-->
<!--                  matTooltip="Kolonlar">-->
<!--                  <i class="fas fa-columns"></i>-->
<!--                </button>&ndash;&gt;-->

<!--              &lt;!&ndash;        <mat-menu #columnMenu="matMenu">-->
<!--                        <ul class="list-group" (click)="$event.stopPropagation()">-->
<!--                          <li class="list-group-item border-top-0 border-left-0 border-right-0">Kolonlar</li>-->
<!--                          <li *ngFor="let col of allColumns" class="m-2">-->
<!--                            <input type="checkbox" [id]="col.name"-->
<!--                                   (click)="toggle(col)"-->
<!--                                   [checked]="isChecked(col)"/>&nbsp;-->
<!--                            <span>{{ col.display }}</span>-->
<!--                            &lt;!&ndash; <mat-checkbox class="example-margin" (click)="toggle(col)"-->
<!--                                           [checked]="isChecked(col)">{{col.name}}</mat-checkbox>&ndash;&gt;-->
<!--                          </li>-->
<!--                        </ul>-->
<!--                      </mat-menu>&ndash;&gt;-->
<!--            </li>-->
<!--            <li >-->


<!--              <button mat-button [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon></button>-->

<!--              <mat-menu #menu="matMenu">-->
<!--                <mat-checkbox [checked]="true" (click)="visibilityChange('select')" class="example-margin">Seçim</mat-checkbox> <br>-->
<!--                <mat-checkbox [checked]="true" (click)="visibilityChange('id')" class="example-margin" >Detay</mat-checkbox> <br>-->
<!--                <mat-checkbox [checked]="true" (click)="visibilityChange('remoteSupportPermission')" class="example-margin">Uzak Bağlantı</mat-checkbox> <br>-->
<!--                <mat-checkbox [checked]="true" (click)="visibilityChange('title')" class="example-margin" >Firma Adı</mat-checkbox> <br>-->
<!--                <mat-checkbox [checked]="true" (click)="visibilityChange('referenceCode')" class="example-margin" >Referans Kod</mat-checkbox> <br>-->
<!--                <mat-checkbox [checked]="true" (click)="visibilityChange('fullname')" class="example-margin">Yetkili Kiş</mat-checkbox> <br>-->
<!--                <mat-checkbox [checked]="true" (click)="visibilityChange('email')" class="example-margin" >Email</mat-checkbox> <br>-->
<!--                <mat-checkbox [checked]="true" (click)="visibilityChange('expireDate')" class="example-margin">Expire Date</mat-checkbox> <br>-->
<!--                <mat-checkbox [checked]="true" (click)="visibilityChange('activation')" class="example-margin" >Aktivasyon</mat-checkbox> <br>-->
<!--                <mat-checkbox [checked]="true" (click)="visibilityChange('isActive')" class="example-margin">Aktif mi?</mat-checkbox> <br>-->
<!--                <mat-checkbox [checked]="true" (click)="visibilityChange('remainingTime')" class="example-margin" >Kalan Süre</mat-checkbox> <br>-->
<!--                <mat-checkbox [checked]="true" (click)="visibilityChange('licenseType')" class="example-margin">Lisans Tipi</mat-checkbox> <br>-->
<!--                <mat-checkbox [checked]="true" (click)="visibilityChange('licenseStatus')" class="example-margin" >Lisans Durumu</mat-checkbox> <br>-->

<!--              </mat-menu>-->




<!--            </li>-->
<!--          </ul>-->
<!--        </div>-->


<!--        <div class="row">-->


<!--          <app-customer-table (newItemEvent)="selectedChange($event)"  [visibility]="visibility"  [dataSource]="dataSource" [displayedColumns]="displayedColumns" style="width: 100%"></app-customer-table>-->

<!--        </div>-->

<!--        <div class="row" >-->
<!--          <mat-paginator (click)="paginationEvent()" style="width: 100%" [pageSizeOptions]="[5,10,50, 100, 250, 500]"-->
<!--                         aria-label="Select page of users"></mat-paginator>-->
<!--        </div>-->




<!--    </div>-->

    </section>
  </div>

