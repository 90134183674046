export enum HostColumnsEnum {
  Select = "Seçim",
  Edit = "Düzenleme",
  id = "Id",
  connectionString ="Veritabanı URL",
  roConnectionString= "Ro Veritabanı URL",
  isDefault = "Varsayılan mı?",
  Delete = "Silme",
}

export const HostColumnsFalseList = [];

