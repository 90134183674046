<section class="content">
  <div class="container-fluid">
    <div class="block-header">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <ul class="breadcrumb breadcrumb-style ">
            <li class="breadcrumb-item">
              <h4 class="page-title">Müşteri Detayı</h4>
            </li>
            <li class="breadcrumb-item bcrumb-1">
              <a routerLink="/analiz/analiz-anasayfa">
                <i class="fas fa-home"></i> Anasayfa</a>
            </li>
            <li class="breadcrumb-item active">Müşteri Detayı</li>
          </ul>
        </div>
      </div>
    </div>

    <p-toolbar styleClass="bg-white my-3 border-0">
      <div class="p-toolbar-group-left row">



      </div>
      <div class="p-toolbar-group-right">

        <button pButton
                type="button"
                label="Yeni Panel Yerel Bağlantı"
                (click)="newlocalSupportLogin()"
                icon="pi pi-sort-alt"
                iconPos="left"
                class="p-button-raised m-2">
        </button>

        <button pButton
                type="button"
                label="Uzak Bağlantı"
                (click)="supportLogin()"
                icon="pi pi-sort-alt"
                iconPos="left"
                class="p-button-raised p-button-warning m-2">
        </button>



      </div>
    </p-toolbar>


    <app-customer-information [company]="company"></app-customer-information>


  </div>
</section>


<!--<section class="content">-->
<!--  <div class="container-fluid">-->
<!--    <div class="block-header">-->
<!--      <div class="row">-->
<!--        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">-->
<!--          <ul class="breadcrumb breadcrumb-style ">-->
<!--            <li class="breadcrumb-item">-->
<!--              <h4 class="page-title">Müşteri Detayı</h4>-->
<!--            </li>-->
<!--            <li class="breadcrumb-item bcrumb-1">-->
<!--              <a routerLink="/analiz/analiz-anasayfa">-->
<!--                <i class="fas fa-home"></i> Anasayfa</a>-->
<!--            </li>-->
<!--            <li class="breadcrumb-item active">Müşteri Detayı</li>-->
<!--          </ul>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="row clearfix">-->
<!--      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">-->
<!--        <div class="card">-->
<!--           <div class="body">-->

<!--               <ng-template matStepLabel>Genel</ng-template>-->
<!--               <div class="row mt-4 ml-2">-->
<!--                 <p style="font-size: 18px;">-->
<!--                   <strong>{{company?.title}} Müşteri Detayı</strong>-->
<!--                 </p>-->
<!--               </div>-->
<!--             <form [formGroup]="generalForm">-->
<!--               <div class="row mt-3 rowDetail">-->

<!--                 <mat-form-field class="col-md-6" appearance="outline">-->
<!--                   <mat-label>Şirket Adı</mat-label>-->
<!--                   <input matInput formControlName="companyName">-->
<!--                 </mat-form-field>-->
<!--                 <mat-form-field class="col-md-3" appearance="outline">-->
<!--                   <mat-label>Yetkili Personel</mat-label>-->
<!--                   <input matInput formControlName="authorizedPersonnel" >-->
<!--                 </mat-form-field>-->
<!--                 <mat-form-field class="col-md-3" appearance="outline">-->
<!--                   <mat-label>Telefon</mat-label>-->
<!--                   <input matInput formControlName="phone" >-->
<!--                 </mat-form-field>-->

<!--                 <mat-form-field class="col-md-3" appearance="outline">-->
<!--                   <mat-label>Email</mat-label>-->
<!--                   <input matInput formControlName="email" >-->
<!--                 </mat-form-field>-->

<!--                 <mat-form-field class="col-md-3" appearance="outline">-->
<!--                   <mat-label>Kayıt Tarihi</mat-label>-->
<!--                   <input matInput formControlName="createdDate">-->
<!--                 </mat-form-field>-->

<!--                 <mat-form-field class="col-md-3" appearance="outline">-->
<!--                   <mat-label>İndirim Tutarı</mat-label>-->
<!--                   <input matInput formControlName="discountAmount">-->
<!--                 </mat-form-field>-->

<!--                 <mat-form-field class="col-md-3" appearance="outline">-->
<!--                   <mat-label>Bayi Referans Kodu</mat-label>-->
<!--                   <input matInput formControlName="dealerReferenceCode">-->
<!--                 </mat-form-field>-->
<!--                 <mat-form-field class="col-md-5" appearance="outline">-->
<!--                   <mat-label>Adres</mat-label>-->
<!--                   <input matInput formControlName="address">-->
<!--                 </mat-form-field>-->
<!--                 <mat-form-field class="col-md-3" appearance="outline">-->
<!--                   <mat-label>Bitiş Tarihi</mat-label>-->
<!--                   <input matInput formControlName="expireDate">-->
<!--                 </mat-form-field>-->
<!--                 <mat-form-field class="col-md-2" appearance="outline">-->
<!--                   <mat-label>İl</mat-label>-->
<!--                   <input matInput formControlName="province">-->
<!--                 </mat-form-field>-->
<!--                 <mat-form-field class="col-md-2" appearance="outline">-->
<!--                   <mat-label>İlçe</mat-label>-->
<!--                   <input matInput formControlName="district">-->
<!--                 </mat-form-field>-->
<!--                 <mat-form-field class="col-md-3" appearance="outline">-->
<!--                   <mat-label>Vergi Dairesi</mat-label>-->
<!--                   <input matInput formControlName="taxAdministration">-->
<!--                 </mat-form-field>-->
<!--                 <mat-form-field class="col-md-3" appearance="outline">-->
<!--                   <mat-label>Vergi Numarası</mat-label>-->
<!--                   <input matInput formControlName="taxNumber">-->
<!--                 </mat-form-field>-->
<!--                 <mat-form-field appearance="outline" class="example-form-field col-md-3">-->
<!--                   <mat-label>Tarih Seçin</mat-label>-->
<!--                   <input matInput [matDatepicker]="datepicker" formControlName="rememberDate">-->
<!--                   <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>-->
<!--                   <mat-datepicker #datepicker>-->
<!--                     <mat-datepicker-actions>-->
<!--                       <button mat-button matDatepickerCancel>İptal</button>-->
<!--                       <button mat-raised-button color="primary" matDatepickerApply>Kaydet</button>-->
<!--                     </mat-datepicker-actions>-->
<!--                   </mat-datepicker>-->
<!--                 </mat-form-field>-->
<!--                 <mat-form-field class="col-md-3" appearance="outline">-->
<!--                   <mat-label>TCKN</mat-label>-->
<!--                   <input matInput formControlName="identity">-->
<!--                 </mat-form-field>-->
<!--                 <mat-slide-toggle class="col-md-1 mx-auto mt-3" formControlName="emailActivation">-->
<!--                   Aktivasyon-->
<!--                 </mat-slide-toggle>-->

<!--                 <mat-slide-toggle class="col-md-1 mx-auto mt-3" formControlName="isActive">-->
<!--                   Aktif Mi?-->
<!--                 </mat-slide-toggle>-->

<!--                 <mat-slide-toggle class="col-md-1 mx-auto mt-3" formControlName="customerTracking">-->
<!--                   Müşteri Takibi-->
<!--                 </mat-slide-toggle>-->
<!--               </div>-->
<!--             </form>-->
<!--             <div class="text-right">-->
<!--               <button mat-raised-button color="primary" (click)="updateCompany()">Kaydet</button>-->
<!--             </div>-->
<!--           </div>-->

<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</section>-->
