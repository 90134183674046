import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'news-management-index',
  templateUrl: './news-management-index.component.html',
  styleUrls: ['./news-management-index.component.sass']
})
export class NewsManagementIndexComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
