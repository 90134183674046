import {Injectable} from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {environment} from "../../../environments/environment.prod";


export const cmpnyList = [
  {
    Id: "1",
    Code: "ABC123ASD",
    Title: "sametcankaratas",
    Name: "Samet Can",
    Surname: "Karatas",
    TaxNumber: "123123123N",
    TaxOffice: "1232134324O",
    Phone: "05548554614",
    Phone2: "02422424242",
    Email: "sck@mail.com",
    EmailAccessCode: "r0e55MahvXOt5+DPqBis6EBOIyv/80gN",
    Password: "HsJ+RjBWq2INrm2lLEgIyQ==",
    Isactive: true,
    LicenseType: 0,
    ExpireDate: "0001-01-01T00:00:00",
    CreatedDate: "2021-07-01T11:44:29.446046",
    SupportNumber:  "0001-01-01T00:00:00",
    SupportExpireDate: "0001-01-01T00:00:00",
    SectorType: 18,
    CompanyInstanceMerge: null,
    CompanyModuleMerge: null,
    UserCompanyMerge: null,
  }
];

@Injectable()
export class RealInterceptor implements HttpInterceptor {


  constructor() {
  }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const {url, method, headers, body} = request;
    // wrap in delayed observable to simulate server api call
    return of(null).pipe(mergeMap(handleRoute));

    function handleRoute() {

      switch (true) {
        case url.endsWith('/authenticate') && method === 'POST':
          return authenticate();
        default:
          // pass through any requests not handled above
          return convertUrl(url);
      }
    }

    // route functions
    /*  function CompanyList() {
        if (!cmpnyList) {
          return error('Company List is Empty.');
        } else {
          return ok(cmpnyList);
        }
      }*/
    function authenticate() {
      const {email, password} = body;
      const parameterIndex: number = url.indexOf('.com');
      const parameters = url.slice(parameterIndex + 5);
      const companiesUrl = request.clone({
        url: request.url.replace(url, environment.apiUrl + "/api/client/login")
      });
      return next.handle(companiesUrl);
    }
    function convertUrl(fromUrl) {
      const parameterIndex: number = fromUrl.indexOf('.com');
      const parameters = fromUrl.slice(parameterIndex + 5);
      const companiesUrl = request.clone({
        url: request.url.replace(fromUrl, environment.apiUrl + '/' + parameters)
      });
      return next.handle(companiesUrl);
    }

    // helper functions

    // tslint:disable-next-line:no-shadowed-variable
    function ok(body?) {
      return of(new HttpResponse({status: 200, body}));
    }

    function error(message) {
      return throwError({error: {message}});
    }

    function unauthorized() {
      return throwError({status: 401, error: {message: 'Unauthorised'}});
    }

    function isLoggedIn() {
      return headers.get('Authorization') === 'Bearer fake-jwt-token';
    }
  }
}
