export enum PageColumnsEnum {
  Select = "Seçim",
  Id = "Id",
  Code = "Kod",
  Title = "Başlık",
  IsMain = "Temel mi?",
  Crud = "Yetkileri",
  SubPage = "Alt Sayfa"
}

export const PageColumnsEnumFalseList = [];
