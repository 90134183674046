import {Component, Input, NgModule, OnInit} from '@angular/core';
import {CompanyService} from "../../../core/service/company.service";
import { Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'analysis-card',
  templateUrl: './analysis-card.component.html',
  styleUrls: ['./analysis-card.component.css']

})
export class AnalysisCardComponent implements OnInit {

  @Input() Title: string;
  @Input() BackgroundColor: string;
  @Input() isActive: boolean;
  @Input() Count: number;
  @Input () icon:string;
  @Input () listType:string;

  constructor(private companyService: CompanyService) {
  }



  ngOnInit(): void {


  }

}
