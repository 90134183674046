import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Company} from "../models/company";
import {map} from "rxjs/operators";
import {environment} from "../../../environments/environment";
import {PaggingModel} from "../models/pagging-model";
import {CompanyFilterModel} from "../models/company-filter-model";
import {CompanyFilterRequest} from "../models/company-filter-request";
import {CompanyModel} from "../../Models/company-model";


@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private http: HttpClient) {
  }

  companyList(requestPaggingModel: PaggingModel) {
    return this.http.post(`${environment.localApiUrl}/api/client/companyList`, requestPaggingModel)
      .pipe(
        map((company) => {
          return company;
        })
      );
  }
  companyAnalysisList() {
    return this.http.get(`${environment.localApiUrl}/api/client/companyAnalysisList`)
      .pipe(
        map((company) => {
          return company;
        })
      );
  }

  supportLogin(companyCode: any): any {
    return this.http.get(`${environment.localApiUrl}/api/client/supportLogin` , {params: {companyCode}})
      .pipe(
        map((token) => {
          return token;
        })
      );
  }

  filterTextCompany(text: string , page: number, pageSize: number): any {
    return this.http.get(`${environment.localApiUrl}/api/client/filterTextCompany`, {params: {text , page, pageSize}})
      .pipe(
        map((company) => {
          return company;
        })
      );
  }

  whoSignedUpOnThisDate(date: string): any {
    return this.http.get(`${environment.localApiUrl}/api/client/whoSignedUpOnThisDate`, {params: {date}})
      .pipe(
        map((company) => {
          return company;
        })
      );
  }

  companyDetails(id: number): any {
    return this.http.post(`${environment.localApiUrl}/api/client/getCompanyDetail`, id)
      .pipe(
        map((company) => {
          return company;
        })
      );
  }

  updateCompany(company: CompanyModel) {
    return this.http.post(`${environment.localApiUrl}/api/client/updateCompany`, company)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
  filterCompanyList(companyFilter: CompanyFilterRequest){
    return this.http.post(`${environment.localApiUrl}/api/client/filterCompanyList`, companyFilter)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
}
