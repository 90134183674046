export class CompanyFilterModel {
  title?: string;
  name?: string;
  surname?: string;
  email?: string;
  licenseType?: string;
  companyStatus?: string;
  isActive?: number;
  province?: string;
  listType?: string;
  fullname?: string;
}
