import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'rate-packet-chart',
  templateUrl: './rate-packet-chart.component.html',
  styleUrls: ['./rate-packet-chart.component.sass']
})
export class RatePacketChartComponent implements OnInit {

  //region yearly/monthly chart code
  public ChartType: string = 'line';

  public ChartDatasets: Array<any> = [
    {data: [65, 59, 80, 81, 56, 55, 40, 58, 69, 31, 87, 47], label: 'Aylık Paket'},
    {data: [28, 48, 40, 19, 86, 27, 90, 85, 44, 77, 50, 90], label: 'Yıllık Paket'}
  ];

  public ChartLabels: Array<any> = ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'];

  public ChartColors: Array<any> = [
    {
      backgroundColor: 'rgba(105, 0, 132, .2)',
      borderColor: 'rgba(200, 99, 132, .7)',
      borderWidth: 2,
    },
    {
      backgroundColor: 'rgba(0, 137, 132, .2)',
      borderColor: 'rgba(0, 10, 130, .7)',
      borderWidth: 2,
    }
  ];

  public ChartOptions: any = {
    responsive: true
  };

  public ChartClicked(e: any): void {
  }

  public ChartHovered(e: any): void {
  }

  //endregion

  constructor() {
  }

  ngOnInit(): void {
  }

}
