import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderAnalysisIndexComponent } from './order-analysis-index/order-analysis-index.component';
import {OrderAnalysisRoutingModule} from "./order-analysis-routing.module";



@NgModule({
  declarations: [
    OrderAnalysisIndexComponent
  ],
  imports: [
    CommonModule,
    OrderAnalysisRoutingModule
  ]
})
export class OrderAnalysisModule { }
