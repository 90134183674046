// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  localApiUrl: "https://account.tekviyaservice.com/",
  /*releaseApiUrl: 'api.magenty.com/api/v1',*/
  isMock: true
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.


//https://localhost:44313/
//https://account.tekviyaservice.com/
//https://account-dev.tekviyaservice.com/
