import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'analysis-order-card',
  templateUrl: './analysis-order-card.component.html',
  styleUrls: ['./analysis-order-card.component.css']
})
export class AnalysisOrderCardComponent implements OnInit {

  public chartType: string = 'doughnut';


  public chartDatasets: Array<any> = [
    {data: [300, 50, 100, 40, 120]}
  ];

  public chartLabels: Array<any> = ['N11', 'ÇİÇEKSEPETİ', 'HEPSİBURADA', 'TRENDYOL', 'EPTTAVM'];

  public chartColors: Array<any> = [
    {
      backgroundColor: ['#F7464A', '#46BFBD', '#FDB45C', '#949FB1', '#4D5360'],
      hoverBackgroundColor: ['#FF5A5E', '#5AD3D1', '#FFC870', '#A8B3C5', '#616774'],
      borderWidth: 2,
    }
  ];

  public chartOptions: any = {
    responsive: true
  };

  public chartClicked(e: any): void {
  }

  public chartHovered(e: any): void {
  }

  constructor() {
  }

  ngOnInit(): void {
  }

}
