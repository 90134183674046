<div class="container-fluid">
    <section class="content">
        <div class="block-header">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <ul class="breadcrumb breadcrumb-style ">
                        <li class="breadcrumb-item">
                            <h4 class="page-title">Admin Yönetimi</h4>
                        </li>
                        <li class="breadcrumb-item bcrumb-1">
                            <a routerLink="/analiz/analiz-anasayfa">
                                <i class="fas fa-home"></i> Anasayfa</a>
                        </li>
                        <li class="breadcrumb-item active">Admin Yönetimi</li>
                    </ul>
                </div>
            </div>
        </div>
      <app-general-table tableType="{{+tableTypeEnum.Admin}}" (add)="adminAdd()" (edit)="adminUpdate($event)" (delete)="adminDelete($event)" ></app-general-table>


    </section>
</div>
