<div class="card">

  <div class="card-body">

    <div style="display: block; width: 500px;">
      <canvas mdbChart
              [chartType]="chartType"
              [datasets]="chartDatasets"
              [labels]="chartLabels"
              [colors]="chartColors"
              [options]="chartOptions"
              [legend]="true"
              (chartHover)="chartHovered($event)"
              (chartClick)="chartClicked($event)">
      </canvas>
    </div>
  </div>
</div>
