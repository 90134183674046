export enum CompanyStatusEnum {
  null ,
  Demo = 1,
  Licensed = 2,
  LicenseExpired = 3
}

export enum CompanyStatusEnumTR {
  null = 'Demo' ,
  Demo = 'Demo',
  Licensed = 'Lisans',
  LicenseExpired = 'Lisans Bitmiş'
}
