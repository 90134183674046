import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Page404Component } from './authentication/page404/page404.component';
import { AuthGuard } from './core/guard/auth.guard';
import { AuthLayoutComponent } from './layout/app-layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layout/app-layout/main-layout/main-layout.component';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'analiz',
        loadChildren: () =>
          import('./pages/analysis/analysis.module').then((m) => m.AnalysisModule)
      },
      {
        path: 'musteri',
        loadChildren: () =>
          import('./pages/customer-management/customer-management.module').then((m) => m.CustomerManagementModule)
      },
      {
        path: 'host',
        loadChildren: () =>
          import('./pages/instance-management/instance-management.module').then((m) => m.InstanceManagementModule)
      },
      {
        path: 'admins',
        loadChildren: () =>
          import('./pages/admins-management/admins-management.module').then((m) => m.AdminsManagementModule)
      },
      {
        path: 'emir',
        loadChildren: () =>
          import('./pages/orders-management/orders-management.module').then((m) => m.OrdersManagementModule)
      },
      {
        path: 'sayfalar',
        loadChildren: () =>
          import('./pages/pages-management/pages-management.module').then((m) => m.PagesManagementModule)
      },
      {
        path: 'siparis',
        loadChildren: () =>
          import('./pages/order-analysis/order-analysis.module').then((m) => m.OrderAnalysisModule)
      },
      {
        path: 'destek',
        loadChildren: () =>
          import('./pages/support-management/support-management.module').then((m) => m.SupportManagementModule)
      },
      {
        path: 'haber',
        loadChildren: () =>
          import('./pages/news-management/news-management.module').then((m) => m.NewsManagementModule)
      },
      {
        path: 'musteri-gorusme',
        loadChildren: () =>
          import('./pages/customer-conversations/customer-conversations.module').then((m) => m.CustomerConversationsModule)
      },
      { path: '', redirectTo: '/authentication/signin', pathMatch: 'full' },
    ]
  },
  {
    path: 'authentication',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      )
  },
  { path: '**', component: Page404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
