<div class="info-box7  order-info-box7" [ngClass]="BackgroundColor">
    <div class="info-box7-block">
      <h3 class="m-b-20">{{Title}}</h3>
      <h2 class="text-end"><i class="fas  float-start" [ngClass]="icon"></i> &nbsp; <span>{{Count}}</span></h2>
      <div class="align-right ">
        Detay
       <a  routerLink="/musteri/musteri-yonetimi/{{listType}}"    class="   fas  float-start fas fa-play text-end"></a>
      </div>
    </div>
  </div>
