import {CompanyInstanceMergeModel} from "./company-instance-merge-model";

export class CompanyModel {
  id: number;
  code: string;
  title: string;
  name: string;
  surname: string;
  identity: string;
  address: string;
  customNote: string;
  province: string;
  district: string;
  fullname: string;
  taxNumber: string;
  taxOffice: string;
  phone: string;
  phone2: string;
  email: string;
  emailAccessCode: string;
  password: string;
  isActive: boolean;
  licenseType: number;
  expireDate: Date;
  createdDate: Date;
  supportNumber: string;
  supportExpireDate: Date;
  sectorType: any;
  customerTracking: boolean;
  referenceCode: string;
  remainingTime: string;
  emailActivation: boolean;
  rememberDate: Date;
  companyInstanceMerge: CompanyInstanceMergeModel;
  companyModuleMerges: any;
  userCompanyMerges: any;


}
