import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {OrderAnalysisIndexComponent} from "./order-analysis-index/order-analysis-index.component";

const routes: Routes = [
  {
    path: '',
    redirectTo: 'siparis/siparis-analizi',
    pathMatch: 'full'
  },
  {
    path: 'siparis/siparis-analizi',
    component: OrderAnalysisIndexComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OrderAnalysisRoutingModule { }
