import {HostColumnsEnum, HostColumnsFalseList} from "./columns-enums/host-columns-enum";
import {AdminColumnsEnum, AdminColumnsFalseList} from "./columns-enums/admin-columns-enum";
import {OrderColumnsEnum, OrderColumnsEnumFalseList} from "./columns-enums/order-columns-enum";
import {PageColumnsEnum, PageColumnsEnumFalseList} from "./columns-enums/page-columns-enum";
import {CustomerColumnsEnum, CustomerColumnsFalseList} from "./columns-enums/customer-columns-enum";

export enum GeneralTableColumnsGeneratorEnum {
  Host = Object(HostColumnsEnum) ,
  Admin = Object(AdminColumnsEnum),
  Order = Object(OrderColumnsEnum),
  Page = Object(PageColumnsEnum),
  Customer = Object(CustomerColumnsEnum),
}

export enum GeneralTableVisibilityColumnsGeneratorEnum {
  Host = Object(HostColumnsFalseList) ,
  Admin = Object(AdminColumnsFalseList),
  Order = Object(OrderColumnsEnumFalseList),
  Page = Object(PageColumnsEnumFalseList),
  Customer = Object(CustomerColumnsFalseList),
}
