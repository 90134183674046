import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
export interface DialogData {
  text: string;
  title: string;
}

@Component({
  selector: 'app-just-okay',
  templateUrl: './just-okay.component.html',
  styleUrls: ['./just-okay.component.sass']
})
export class JustOkayComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<JustOkayComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  ngOnInit(): void {
  }


}
