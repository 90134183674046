import { Component, OnInit } from '@angular/core';
import {OrderFilterModel} from "../../../Models/order-filter-model";
import {DynamicDialogRef} from "primeng/dynamicdialog";

@Component({
  selector: 'app-orders-filter',
  templateUrl: './orders-filter.component.html',
  styleUrls: ['./orders-filter.component.sass']
})
export class OrdersFilterComponent implements OnInit {
 order: OrderFilterModel = new OrderFilterModel();
  constructor(public ref: DynamicDialogRef) { }
  status = [
    {name: 'Sipariş Durumu', code: 4},
    {name: 'Onay Bekliyor', code: 0},
    {name: 'Onaylandı', code: 1},
    {name: 'Reddedildi', code: 2},
  ];
  paymentMethod = [
    {name: 'Ödeme Türü', code: 4},
    {name: 'Kredi Kartı', code: 0},
    {name: 'Havale / EFT', code: 1},
    {name: 'Diğer', code: 2},
  ];
  isYearly = [
    {name: 'Plan Türü', code: 0},
    {name: 'Yıllık', code: 1},
    {name: 'Aylık', code: 2},
  ];
  ngOnInit(): void {
    this.order.orderNumber = "";
    this.order.paymentNumber = "";
    this.order.status = 4;
    this.order.paymentMethod = 4;
    this.order.isYearly = 0;
  }


  saveFilter = () => {
    const defaultFilter: OrderFilterModel = {
      orderNumber: "",
      paymentNumber: "",
      status: 4,
      paymentMethod: 4,
      isYearly : 0
    };
    if (JSON.stringify(this.order) === JSON.stringify(defaultFilter))
    {
      this.ref.close(false);
    }
    else
    {
      console.log(this.order);
      this.ref.close(this.order);
    }
  }

}
