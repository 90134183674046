export enum CustomerColumnsEnum {
  Select = "Seçim",
  EditCustomer = "Detay",
   id = "Id",
  code = "Kod",
  title = "Firma Adı" ,
  // name = "Ad",
  // surname = "Soyad",
  identity = "Kimlik" ,
  address = "Adres" ,
  province = "İl" ,
  district = "İlçe" ,
  fullname = "Yetkili Kişi" ,
  taxNumber = "Vergi Numara" ,
  taxOffice = "Vergi Dairesi" ,
  phone = "Telefon" ,
  phone2 = "Telefon 2" ,
  email = "Email" ,
  isActive = "Aktif Mi?" ,
  // licenseType = "Lisans Tipi" ,
  createdDate = "Üyelik Tarihi" ,
  supportNumber = "Destek Numarası" ,
  supportExpireDate = "Destek Bitiş Tarihi" ,
  sectorType = "Sektör Türü" ,
  customerTracking = "Müşteri Takip" ,
  remainingTime = "Kalan Süre" ,
  companyStatus = "Lisans Türü" ,
  licenseStatus = "Lisans Durumu" ,
  expireDate = "Bitiş Tarihi" ,
  emailActivation = "Email Onaylandı mı?" ,
  rememberDate = "Hatırlatma Tarihi",
  referenceCode = "Referans Kod",
  loginLastDate = "Son Giriş Tarihi",

}
export const CustomerColumnsFalseList = ["phone2", "identity" , "customerTracking", "supportNumber" , "sectorType", "createdDate", "supportExpireDate", "rememberDate", "address" , "district", "taxNumber", "taxOffice"];
