import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';
import {OrderFilterModel} from "../../Models/order-filter-model";

@Injectable({
  providedIn: 'root'
})

export class OrdersService {

  constructor(private http: HttpClient) { }

  getOrders() {
    return this.http.get(`${environment.localApiUrl}/api/order/orderList`)
      .pipe(
        map((orders) => {
          console.log(orders);
          return orders;
        })
      );

  }

    sendInvoice(orderid: number) {
        return this.http.post(`${environment.localApiUrl}/api/order/sendInvoice?orderid=${orderid}`, orderid)
            .pipe(
                map((orders) => {
                    return orders;
                })
            );

    }
    GetInvoicePdf(uuid: number, type: number) {
        return this.http.post(`${environment.localApiUrl}/api/order/GetInvoicePdf?uuid=${uuid}&invoicetype=${type}`, uuid)
            .pipe(
                map((orders) => {
                    return orders;
                })
            );

    }

    update(order: number) {
        return this.http.post(`${environment.localApiUrl}/api/order/update`, order)
            .pipe(
                map((orders) => {
                    return orders;
                })
            );

    }

    orderdetail(orderid: number) {
        return this.http.post(`${environment.localApiUrl}/api/order/orderdetail?orderid=${orderid}`, orderid)
            .pipe(
                map((orders) => {
                    return orders;
                })
            );

    }
    orderconfirm(orderid: number) {
        return this.http.post(`${environment.localApiUrl}/api/order/orderconfirm?orderid=${orderid}`, orderid)
            .pipe(
                map((orders) => {
                    return orders;
                })
            );

    }

  filterOrders(order: OrderFilterModel) {
    return this.http.post(`${environment.localApiUrl}/api/order/filter`, order)
      .pipe(
        map((orders) => {
          return orders;
        })
      );

  }
}
