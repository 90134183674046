import {Component, Inject, Input, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {CompanyService} from "../../../core/service/company.service";
import {Router, ActivatedRoute, Params} from '@angular/router';
import {MatDialog} from "@angular/material/dialog";
import {JustOkayComponent} from "../../helper-components/just-okay/just-okay.component";
import {Company} from "../../../core/models/company";
import {CompanyModel} from "../../../Models/company-model";

@Component({
  selector: 'app-customer-detail',
  templateUrl: './customer-detail.component.html',
  styleUrls: ['./customer-detail.component.scss']
})
export class CustomerDetailComponent implements OnInit {

  company: CompanyModel = new  CompanyModel();
  id: any;

  constructor(private companyService: CompanyService,
              private router: Router,
              private route: ActivatedRoute,
              private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.id = params.id;
        this.companyService.companyDetails(+this.id)
        .subscribe((res: any) => {
          if (res.status === true) {
            this.company = res.data;
            console.log(res.data);

          } else {
            this.createJustOkayModal('Hata',
              'Bir hata oluştu. Lütfen tekrar deneyin.');
          }
        });
    });


  }

  supportLogin = () =>
  {
    this.companyService.supportLogin(this.company.code).subscribe((res: any) => {
      if (res.status) {
        let token = res.data;
        token = token.split(" ");

        window.open("https://v2.magenty.com/changeToken/" + token[1]);

      }
    });

  }


  newlocalSupportLogin = () =>{
    {
      this.companyService.supportLogin(this.company.code).subscribe((res: any) => {
        if (res.status) {
          let token = res.data;
          token = token.split(" ");

          window.open("http://localhost:4201/changeToken/" + token[1]);

        }
      });

    }
  }



  date = () => {
    return "07/16/22";
  }

  // updateCompany() {
  //   const updateCompany = this.mergeCompany();
  //   this.companyService.updateCompany(updateCompany)
  //     .subscribe((res: any) => {
  //       console.log(res);
  //       if (res.status === true) {
  //         this.createJustOkayModal('Başarılı',
  //           'Firma bilgileri başarıyla güncellendi.');
  //       }
  //     });
  // }




  createJustOkayModal = (title, text) => {
     this.dialog.open(JustOkayComponent, {
      width: '400px',
      data: {text, title}
    });
  }


}
