export enum OrderColumnsEnum {
  Select = "Seçim",
  Edit = "Düzenle",
  id = "Id",
  companyName = "Şirket",
  createdDate = "Sipariş Tarihi",
  // updatedDate = "Güncellenme Tarihi",
  orderNumber = "Sipariş Numarası",
  paymentNumber = "Ödeme Numarası",
  Orderstatus = "Durumu",
  discountTotal = "İndirim Tutarı",
  paymentTotal = "Ödeme Tutarı",
  paymentDescription = "Ödeme Açıklaması",
  moduleId = "Paketi",
  paymentMethod = "Ödeme Türü",
  isYearly = "Yıllık mı?",
  invoiceNumber = "Fatura Numarası",
  extraProduct = "Ekstra Ürün",
  extraPackages = "Ekstra Paket",
  selectedMarketplaces = "Seçilmiş Pazaryerleri",
  selectedSpecialCargo = "Seçilmiş Kargo Şirketleri",

  // companyId = "Şirket Numarası",
  // company = "Şirket",
  // module = "Modül",


}

export const OrderColumnsEnumFalseList = [];
