import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'order-analysis-index',
  templateUrl: './order-analysis-index.component.html',
  styleUrls: ['./order-analysis-index.component.sass']
})
export class OrderAnalysisIndexComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
