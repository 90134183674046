export enum AdminColumnsEnum {
  Select = "Seçim",
  Edit = "Düzenle",
  id = "Id",
  email = "Mail",
  password = "Parola",
  adminType = "Admin Türü",
  name = "Ad",
  surname = "Soyad",
  dealerCode = "Bayi Kodu",
  phone = "Telefon",
  isActive = "Aktif mi?",
  Delete = "Admin Silme"
}
export const AdminColumnsFalseList = [];
