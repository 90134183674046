import {Component, OnInit, ViewChild} from '@angular/core';
import { AdminsModule } from 'src/app/core/models/admins-module';
import { AdminsResponseModel } from 'src/app/core/models/admins-response-module';
import { AdminsService } from 'src/app/core/service/admins.service';
import {TableTypeEnum} from "../../../components/general-table/enums/table-type-enum";
import {InstanceAddUpdateComponent} from "../../../components/instance-management/instance-add-update/instance-add-update.component";
import {DialogService} from "primeng/dynamicdialog";
import {AdminsAddUpdateComponent} from "../../../components/admins-management/admins-add-update/admins-add-update.component";
import {InformationDialogComponent} from "../../../components/helper-components/information-dialog/information-dialog.component";
import {GeneralTableComponent} from "../../../components/general-table/general-table/general-table.component";

@Component({
  selector: 'app-admins-management',
  templateUrl: './admins-management.component.html',
  styleUrls: ['./admins-management.component.sass']
})
export class AdminsManagementComponent implements OnInit {
  @ViewChild(GeneralTableComponent) generalTable: GeneralTableComponent;
  getAdminsResponse: AdminsResponseModel = new AdminsResponseModel;
  getAdmins: AdminsModule = new AdminsModule;
  public data: any;



  public get tableTypeEnum(): typeof TableTypeEnum { return TableTypeEnum; }
  constructor(private adminsService: AdminsService,
              public dialogService: DialogService) { }



  ngOnInit(): void {

  }
  adminAdd = () => {
    const ref = this.dialogService.open(AdminsAddUpdateComponent, {
      header: 'Admin Ekle',
      contentStyle: {"max-height": "500px", overflow: "auto"},
      baseZIndex: 10000
    });

    ref.onClose.subscribe((status: any) => {
      if (status === true)
      {
        this.dialogService.open(InformationDialogComponent, {
          data: {
            text: 'Admin ekleme işlemi başarılı.',
          },
          header: "Bilgi",
        });
        this.generalTable.request();
      }

      if (status === false)
      {
        this.dialogService.open(InformationDialogComponent, {
          data: {
            text: 'Admin eklenemedi.',
          },
          header: "Hata",
        });
      }

    });
  }

  adminUpdate = (admin: any) => {
    const ref = this.dialogService.open(AdminsAddUpdateComponent, {
      header: 'Admin Güncelle',
      contentStyle: {"max-height": "500px", overflow: "auto"},
      baseZIndex: 10000,
      data: admin
    });

    ref.onClose.subscribe((status: any) => {
      if (status === true)
      {
        this.dialogService.open(InformationDialogComponent, {
          data: {
            text: 'Admin güncelleme işlemi başarılı.',
          },
          header: "Bilgi",
        });
        this.generalTable.request();
      }

      if (status === false)
      {
        this.dialogService.open(InformationDialogComponent, {
          data: {
            text: 'Admin güncellenmedi.',
          },
          header: "Hata",
        });
      }

    });

  }

  adminDelete = (admin: any) => {

    this.adminsService.deleteAdmin(admin).subscribe((res: any) => {
      console.log(res);
    });
    console.log(admin);
  }

}
