import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '/analiz',
    title: 'Analiz',
    moduleName: 'analysis',
    icon: 'fas fa-chart-line',
    class: 'menu-toggle',

    groupTitle: false,
    submenu: [],
    submenuIcon: null
  },
  {
    path: '/musteri/musteri-yonetimi/0',
    title: 'Müşteri Yönetimi',
    moduleName: 'customer-management',
    icon: 'far fa-id-card',
    class: 'menu-toggle',

    groupTitle: false,
    submenu: [],
    submenuIcon: null
  },
  {
    path: '/host/host-yonetimi',
    title: 'Host Yönetimi',
    moduleName: 'instance-managment',
    icon: 'fa fa-database',
    class: 'menu-toggle',

    groupTitle: false,
    submenu: [],
    submenuIcon: null
  },
  {
    path: '/admins/admins-yonetimi',
    title: 'Admin Yönetimi',
    moduleName: 'admins-management',
    icon: 'fa fa-user',
    class: 'menu-toggle',

    groupTitle: false,
    submenu: [],
    submenuIcon: null
  },
  {
    path: '/emir/emirler-yonetimi',
    title: 'Sipariş Yönetimi',
    moduleName: 'orders-management',
    icon: 'fa fa-list-ul',
    class: 'menu-toggle',

    groupTitle: false,
    submenu: [],
    submenuIcon: null
  },
  {
    path: '/sayfalar/sayfalar-yonetimi',
    title: 'Sayfa Yönetimi',
    moduleName: 'pages-management',
    icon: 'fa fa-file',
    class: 'menu-toggle',

    groupTitle: false,
    submenu: [],
    submenuIcon: null
  },


  // {
  //   path: '/destek/destek-yonetimi',
  //   title: 'Destek Yönetimi',
  //   moduleName: 'support-management',
  //   icon: 'fas fa-phone-square',
  //   class: 'menu-toggle',
  //
  //   groupTitle: false,
  //   submenu: [],
  //   submenuIcon: null
  // },
  // {
  //   path: '/haber/haber-yonetimi',
  //   title: 'Haber Yönetimi',
  //   moduleName: 'support-management',
  //   icon: 'far fa-newspaper',
  //   class: 'menu-toggle',
  //
  //   groupTitle: false,
  //   submenu: [],
  //   submenuIcon: null
  // },
  // {
  //   path: '/musteri/musteri-gorusmeleri',
  //   title: 'Müşteri Görüşmeleri',
  //   moduleName: 'customer-conversations',
  //   icon: 'far fa-comments',
  //   class: 'menu-toggle',
  //
  //   groupTitle: false,
  //   submenu: [],
  //   submenuIcon: null
  // }
];
