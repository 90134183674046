import {NgModule} from '@angular/core';
import {CoreModule} from './core/core.module';
import {SharedModule} from './shared/shared.module';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HeaderComponent} from './layout/header/header.component';
import {PageLoaderComponent} from './layout/page-loader/page-loader.component';
import {SidebarComponent} from './layout/sidebar/sidebar.component';
import {RightSidebarComponent} from './layout/right-sidebar/right-sidebar.component';
import {AuthLayoutComponent} from './layout/app-layout/auth-layout/auth-layout.component';
import {MainLayoutComponent} from './layout/app-layout/main-layout/main-layout.component';
import {environment} from "../environments/environment";
import {NgxSpinnerModule} from 'ngx-spinner';
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {FakeBackendInterceptor, fakeBackendProvider} from './core/interceptor/fake-backend';
import {ErrorInterceptor} from './core/interceptor/error.interceptor';
import {JwtInterceptor} from './core/interceptor/jwt.interceptor';
import {LocationStrategy, HashLocationStrategy, registerLocaleData} from '@angular/common';
import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {ClickOutsideModule} from 'ng-click-outside';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient
} from '@angular/common/http';
import {WINDOW_PROVIDERS} from './core/service/window.service';
import {AnalysisModule} from "./pages/analysis/analysis.module";
import {CustomerManagementModule} from "./pages/customer-management/customer-management.module";
import {NewsManagementModule} from "./pages/news-management/news-management.module";
import {OrderAnalysisModule} from "./pages/order-analysis/order-analysis.module";
import {SupportManagementModule} from "./pages/support-management/support-management.module";
import {MatMenuModule} from "@angular/material/menu";
import {RealInterceptor} from "./core/interceptor/real.interceptor";
import {NZ_I18N} from "ng-zorro-antd/i18n";
import {NgDialogAnimationService} from "ng-dialog-animation";
import {en_US} from 'ng-zorro-antd/i18n';
import en from '@angular/common/locales/en';
import { NzMessageModule } from 'ng-zorro-antd/message';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDialogModule} from "@angular/material/dialog";
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatTabsModule} from "@angular/material/tabs";
import {MatButtonModule} from "@angular/material/button";
import {MatCardModule} from "@angular/material/card";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatInputModule} from "@angular/material/input";
import {MatChipsModule} from "@angular/material/chips";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatIconModule} from "@angular/material/icon";
import {NzSelectModule} from "ng-zorro-antd/select";
import {ShContextMenuModule} from "ng2-right-click-menu";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatStepperModule} from "@angular/material/stepper";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {CKEditorModule} from "@ckeditor/ckeditor5-angular";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatTableModule} from "@angular/material/table";
import { JustOkayComponent } from './components/helper-components/just-okay/just-okay.component';
import { CustomerFilterComponent } from './components/customer-management/customer-filter/customer-filter.component';
import {MatSelectModule} from "@angular/material/select";
import {MatRadioModule} from "@angular/material/radio";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {ConfirmationService, MessageService} from "primeng/api";
import {ButtonModule} from "primeng/button";
import { AdminsAddUpdateComponent } from './components/admins-management/admins-add-update/admins-add-update.component';
import {InputTextModule} from "primeng/inputtext";
import {RippleModule} from "primeng/ripple";
import {DropdownModule} from "primeng/dropdown";
import {InputMaskModule} from "primeng/inputmask";
import {SelectButtonModule} from "primeng/selectbutton";
import {InputSwitchModule} from "primeng/inputswitch";
import { InformationDialogComponent } from './components/helper-components/information-dialog/information-dialog.component';
import {LoadingBarHttpClientModule} from "@ngx-loading-bar/http-client";
import {TuiRootModule} from "@taiga-ui/core";



registerLocaleData(en);
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false
};

export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

export const isMockData = environment.isMock;

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        PageLoaderComponent,
        SidebarComponent,
        RightSidebarComponent,
        AuthLayoutComponent,
        MainLayoutComponent,
        SidebarComponent,
        HeaderComponent,
        JustOkayComponent,
        CustomerFilterComponent,
        AdminsAddUpdateComponent,
        InformationDialogComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        LoadingBarHttpClientModule,
        AppRoutingModule,
        TuiRootModule,
        HttpClientModule,
        PerfectScrollbarModule,
        ClickOutsideModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        CoreModule,
        SharedModule,
        AnalysisModule,
        CustomerManagementModule,
        NewsManagementModule,
        OrderAnalysisModule,
        SupportManagementModule,
        NgxDatatableModule,
        MatMenuModule,
        NzMessageModule,
        ReactiveFormsModule,
        FormsModule,
        MatFormFieldModule,
        MatDialogModule,
        NzNotificationModule,
        MatTooltipModule,
        MatTabsModule,
        MatButtonModule,
        MatCardModule,
        MatSlideToggleModule,
        MatInputModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatIconModule,
        NzSelectModule,
        ShContextMenuModule,
        MatCheckboxModule,
        MatStepperModule,
        DragDropModule,
        CKEditorModule,
        NgxSpinnerModule,
        MatPaginatorModule,
        MatSnackBarModule,
        MatTableModule,
        MatSelectModule,
        MatRadioModule,
        ButtonModule,
        InputTextModule,
        RippleModule,
        DropdownModule,
        InputMaskModule,
        SelectButtonModule,
        InputSwitchModule
    ],
    /* providers: [
       {provide: LocationStrategy, useClass: HashLocationStrategy},
       {
         provide: PERFECT_SCROLLBAR_CONFIG,
         useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
       },
       {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
       {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
       WINDOW_PROVIDERS
     ],*/
    providers: [
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: isMockData ? FakeBackendInterceptor : RealInterceptor, multi: true },
        WINDOW_PROVIDERS,
        { provide: NZ_I18N, useValue: en_US },
        NgDialogAnimationService,
        DialogService,
        MessageService,
        ConfirmationService
    ],
    exports: [
        CustomerFilterComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
