<div style="display: block; width: 500px;">
  <canvas mdbChart
          [chartType]="ChartType"
          [datasets]="ChartDatasets"
          [labels]="ChartLabels"
          [colors]="ChartColors"
          [options]="ChartOptions"
          [legend]="true"
          (chartHover)="ChartHovered($event)"
          (chartClick)="ChartClicked($event)">
  </canvas>
</div>
