import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'dealer-packet-chart',
  templateUrl: './dealer-packet-chart.component.html',
  styleUrls: ['./dealer-packet-chart.component.sass']
})
export class DealerPacketChartComponent implements OnInit {

  //region dealer chart code
  public ChartType: string = 'bar';

  public ChartDatasets: Array<any> = [
    {data: [65, 59, 55, 81, 56, 55, 40], label: 'Tanışma Paketi-P1'},
    {data: [30, 12, 15, 13, 14, 15, 16], label: 'Giriş Paketi-P2'},
    {data: [15, 40, 30, 25, 15, 28, 55], label: 'Popüler Paketi-P3'},
    {data: [50, 75, 88, 40, 98, 15, 55], label: 'Ultra Paket-P4'},
    {data: [10, 28, 14, 17, 24, 65, 66], label: 'Sınırsız Paket-P5'},
  ];

  public ChartLabels: Array<any> = ['Bayii 1', 'Bayii 2', 'Bayii 3', 'Bayii 4', 'Bayii 5'];

  public ChartColors: Array<any> = [
    {
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)'

      ],
      borderColor: [
        'rgba(255,99,132,1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)'

      ],
      borderWidth: 2,
    },
    {
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)'

      ],
      borderColor: [
        'rgba(255,99,132,1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)'

      ],
      borderWidth: 2,
    },
    {
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)'

      ],
      borderColor: [
        'rgba(255,99,132,1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)'

      ],
      borderWidth: 2,
    },
    {
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)'

      ],
      borderColor: [
        'rgba(255,99,132,1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)'

      ],
      borderWidth: 2,
    },
    {
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)'

      ],
      borderColor: [
        'rgba(255,99,132,1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)'

      ],
      borderWidth: 2,
    },

  ];

  public ChartOptions: any = {
    responsive: true,
    scales: {
      xAxes: [{
        stacked: true
      }],
      yAxes: [
        {
          stacked: true
        }
      ]
    }
  };

  public ChartClicked(e: any): void {
  }

  public ChartHovered(e: any): void {
  }

  //endregion
  constructor() {
  }

  ngOnInit(): void {
  }

}
