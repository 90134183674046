import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'support-management-index',
  templateUrl: './support-management-index.component.html',
  styleUrls: ['./support-management-index.component.sass']
})
export class SupportManagementIndexComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    console.log('sa');
  }

}
