import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class PagesService {

  constructor(private http: HttpClient) { }

  getPages() {
    return this.http.get(`${environment.localApiUrl}/api/page/pageList`)
      .pipe(
        map((pages) => {
          return pages;
        })
      );
  }
}
