<section class="content">
  <div class="container-fluid">
    <div class="block-header">
      <div class="row ">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <ul class="breadcrumb breadcrumb-style ">
            <li class="breadcrumb-item">
              <h4 class="page-title">Analiz</h4>
            </li>
            <li class="breadcrumb-item bcrumb-1">
              <a routerLink="/analiz/analiz-anasayfa">
                <i class="fas fa-home"></i> Anasayfa</a>
            </li>
            <li class="breadcrumb-item bcrumb-2">
              <a href="#" onClick="return false;">Analiz</a>
            </li>

          </ul>
        </div>
      </div>
    </div>



      <div class="grid">
        <div class="col-12 md:col-4 lg:col-2">
          <div class="surface-card shadow-2 p-3 border-round">
            <div class="flex justify-content-between mb-3">
              <div>
                <span class="block text-500 font-medium mb-3">Müşteri Sayısı</span>
                <div class="text-900 font-medium text-xl">{{companyCount?.costumerCount}}</div>
              </div>
              <div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width:2.5rem;height:2.5rem">
                <i class="pi pi-users text-blue-500 text-xl"></i>
              </div>
            </div>
            <span class="text-green-500 font-medium"> &nbsp;</span>
            <span class="text-500">&nbsp;</span>
          </div>
        </div>
        <div class="col-12 md:col-4 lg:col-2">
          <div class="surface-card shadow-2 p-3 border-round">
            <div class="flex justify-content-between mb-3">
              <div>
                <span class="block text-500 font-medium mb-3">1 Ay Kalan</span>
                <div class="text-900 font-medium text-xl">{{companyCount.oneMonthCount}}</div>
              </div>
              <div class="flex align-items-center justify-content-center bg-green-100 border-round" style="width:2.5rem;height:2.5rem">
                <i class="pi pi-check-circle text-green-500  text-xl"></i>
              </div>
            </div>
            <span class="text-green-500 font-medium">&nbsp; </span>
            <span class="text-500">&nbsp;</span>
          </div>
        </div>
        <div class="col-12 md:col-4 lg:col-2">
          <div class="surface-card shadow-2 p-3 border-round">
            <div class="flex justify-content-between mb-3">
              <div>
                <span class="block text-500 font-medium mb-3">1 Hafta Kalan</span>
                <div class="text-900 font-medium text-xl">{{companyCount.oneWeekCount}}</div>
              </div>
              <div class="flex align-items-center justify-content-center bg-yellow-100 border-round" style="width:2.5rem;height:2.5rem">
                <i class="pi pi-stopwatch  text-yellow-500 text-xl"></i>
              </div>
            </div>
            <span class="text-yellow-500 font-medium">&nbsp; </span>
            <span class="text-500">&nbsp;</span>
          </div>
        </div>
        <div class="col-12 md:col-4 lg:col-2">
          <div class="surface-card shadow-2 p-3 border-round">
            <div class="flex justify-content-between mb-3">
              <div>
                <span class="block text-500 font-medium mb-3">Süresi Dolanlar</span>
                <div class="text-900 font-medium text-xl">{{companyCount.expiredCount}}</div>
              </div>
              <div class="flex align-items-center justify-content-center bg-red-100 border-round" style="width:2.5rem;height:2.5rem">
                <i class="pi pi-calendar-times text-red-500 text-xl"></i>
              </div>
            </div>
            <span class="text-green-500 font-medium">&nbsp; </span>
            <span class="text-500">&nbsp;</span>
          </div>
        </div>
        <div class="col-12 md:col-4 lg:col-2">
          <div class="surface-card shadow-2 p-3 border-round">
            <div class="flex justify-content-between mb-3">
              <div>
                <span class="block text-500 font-medium mb-3">Takip Edilenler</span>
                <div class="text-900 font-medium text-xl">{{companyCount.follewedByCount}}</div>
              </div>
              <div class="flex align-items-center justify-content-center bg-purple-100 border-round" style="width:2.5rem;height:2.5rem">
                <i class="pi pi-user-edit text-purple-500 text-xl"></i>
              </div>
            </div>
            <span class="text-green-500 font-medium">&nbsp; </span>
            <span class="text-500">&nbsp;</span>
          </div>
        </div>
        <div class="col-12 md:col-4 lg:col-2">
          <div class="surface-card shadow-2 p-3 border-round">
            <div class="flex justify-content-between mb-3">
              <div>
                <span class="block text-500 font-medium mb-3">Demo Aktifler</span>
                <div class="text-900 font-medium text-xl">{{companyCount.activeDemoCount}}</div>
              </div>
              <div class="flex align-items-center justify-content-center bg-purple-100 border-round" style="width:2.5rem;height:2.5rem">
                <i class="pi pi-user-plus text-purple-500 text-xl"></i>
              </div>
            </div>
            <span class="text-green-500 font-medium">&nbsp; </span>
            <span class="text-500">&nbsp;</span>
          </div>
        </div>
      </div>


    <div class="flex flex-column md:flex-row gap-3 mt-5 md:align-items-center bg-orange-400 border-round-xl p-3 text-white">
      <div class="flex-none flex flex-column align-items-center md:w-8rem">
        <button pripple="" [disabled]="todayMatch"
                (click)="dateplus()"
                class="p-link inline-flex align-items-center no-underline font-medium cursor-pointer bg-orange-300"><i
          class="pi pi-angle-up"></i></button>
        <div class="flex flex-column align-items-center my-3"><span
          class="block mb-1 text-3xl font-semibold">{{today.getDate()}}</span><span class="mt-0">{{today.toLocaleString("tr-TR",  { month: 'long' })}}</span></div>
        <button pripple=""
                (click)="dateMineus()"
                class="p-link inline-flex align-items-center no-underline font-medium cursor-pointer bg-orange-300"><i
          class="pi pi-angle-down"></i></button>
      </div>
      <div class="flex-1 gap-3 flex flex-column sm:flex-row">
        <div class="flex-1 bg-orange-300 shadow-1 p-3 border-round-md">
          <div class="text-center"><i class="pi pi-users text-5xl mb-3"></i>
            <div class="text-sm font-700 font-semibold mb-2">Yeni Kayıt</div>
            <span class="font-bold">{{signedUpCount}}</span></div>
        </div>

        <div class="flex-1 bg-orange-300 shadow-1 p-3 border-round-md">
          <div class="text-center"><i class="pi pi-verified text-5xl mb-3"></i>
            <div class="text-sm font-700 font-semibold mb-2">Email Onay</div>
            <span class="font-bold">{{emailActivation}}</span></div>
        </div>
        <div class="flex-1 bg-orange-300 shadow-1 p-3 border-round-md">
          <div class="text-center"><i class="pi  pi-sync text-5xl mb-3"></i>
            <div class="text-sm font-700 font-semibold mb-2">Demo Sayısı</div>
            <span class="font-bold">{{demoCount}}</span></div>
        </div>
      </div>
    </div>



    <div class="card mt-5">
            <p-table [value]="dataSource" [paginator]="true" [rows]="10" [showCurrentPageReport]="true" responsiveLayout="scroll"
               currentPageReportTemplate="{totalRecords} Müşteriden {first} ile {last} arası gösterliyor" [rowsPerPageOptions]="[10,25,50]">
        <ng-template pTemplate="header">
          <tr>
            <th class="text-center">id</th>
            <th class="text-center">Detay</th>
            <th class="text-center">Kod</th>
            <th class="text-center">Ad Soyad</th>
            <th class="text-center">Firma Adı</th>
            <th class="text-center">Telefon</th>
            <th class="text-center">Email</th>
            <th class="text-center">Aktif mi?</th>
            <th class="text-center">Lisans Türü</th>
            <th class="text-center">Lisans Durumu</th>
            <th class="text-center">Email Onaylandı mı?</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-customer>
          <tr>
            <td class="text-center">{{customer.id}}</td>
            <td class="text-center">
              <a pButton pRipple  routerLink="../../musteri/musteri-detay/{{customer.id}}" type="button" icon="pi pi-pencil"
                 class="p-button-rounded p-button-text mx-4"></a></td>
            <td class="text-center">{{customer.code}}</td>
            <td class="text-center">{{customer.fullname}}</td>
            <td class="text-center">{{customer.title}}</td>
            <td class="text-center">{{customer.phone}}</td>
            <td class="text-center">{{customer.email}}</td>
            <td class="text-center">{{customer.isActive ? "Evet" : "Hayır"}}</td>
            <td class="text-center">

              <p-tag *ngIf="customer.companyStatus === 0 || customer.companyStatus=== 1"  styleClass="mr-2" value="{{companyStatusName(customer.companyStatus)}}"> </p-tag>
              <p-tag *ngIf="customer.companyStatus === 2 " styleClass="mr-2" severity="warning" value="{{companyStatusName(customer.companyStatus)}}"> </p-tag>
              <p-tag *ngIf="customer.companyStatus=== 3 " styleClass="mr-2" severity="danger" value="{{companyStatusName(customer.companyStatus)}}"> </p-tag>

            </td>
            <td class="text-center">
              <p-tag *ngIf="customer?.remainingTime > 0  " styleClass="mr-2" severity="success" value="Devam Ediyor"> </p-tag>
              <p-tag *ngIf="customer?.remainingTime  <= 0 " styleClass="mr-2" severity="danger" value="Süresi Dolmuş"> </p-tag>
            </td>
            <td class="text-center">{{customer.emailActivation ? "Evet" : "Hayır"}}</td>

          </tr>
        </ng-template>
      </p-table>
    </div>

    <!--    <div class="row">-->
<!--      <div class="col" >-->
<!--        <analysis-card [listType]="'0'" (click)="selectCard(1)" [icon]="'fas fa-child'"  [Title]="'Müşteri Sayısı'" [Count]="companyCount.costumerCount"  [BackgroundColor]="'bg-c-blue'" [isActive]="selectedCard == 1"></analysis-card>-->
<!--      </div>-->
<!--      <div class="col" >-->
<!--        <analysis-card  [listType]="'1'"  (click)="selectCard(2)" [icon]="'fa-calendar-check'"  [Title]="'1 Ay Kalan'" [Count]="companyCount.oneMonthCount"  [BackgroundColor]="'bg-c-green'" [isActive]="selectedCard == 2"></analysis-card>-->
<!--      </div>-->
<!--      <div class="col" >-->
<!--        <analysis-card [listType]="'2'"  (click)="selectCard(3)" [icon]="'fa-calendar-minus'"  [Title]="'1 Hafta Kalan'" [Count]="companyCount.oneWeekCount"  [BackgroundColor]="'bg-c-yellow'" [isActive]="selectedCard == 3"></analysis-card>-->
<!--      </div>-->
<!--      <div class="col" >-->
<!--        <analysis-card [listType]="'3'"  (click)="selectCard(4)" [icon]="'fa-calendar-times'"  [Title]="'Süresi Dolanlar'" [Count]="companyCount.expiredCount"  [BackgroundColor]="'bg-c-pink'" [isActive]="selectedCard == 4"></analysis-card>-->
<!--      </div>-->
<!--      <div class="col" >-->
<!--        <analysis-card  [listType]="'5'" (click)="selectCard(5)"  [icon]="'fa-business-time'" [Title]="'Takip Edilenler'" [Count]="companyCount.follewedByCount"   [BackgroundColor]="'bg-cyan'" [isActive]="selectedCard == 5"></analysis-card>-->
<!--      </div>-->
<!--      <div class="col" >-->
<!--        <analysis-card [listType]="'4'"  (click)="selectCard(6)" [icon]="'fa-street-view'"  [Title]="'Demo Aktifler'" [Count]="companyCount.activeDemoCount"   [BackgroundColor]="'l-bg-red'" [isActive]="selectedCard == 6"></analysis-card>-->
<!--      </div>-->
<!--    </div>-->



<!--    <div class="row clearfix mt-3">-->

<!--      <mat-card  class="col-xs-12 col-sm-12 col-md-12 col-lg-12">-->


<!--        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" >-->
<!--          <mat-tab-group>-->
<!--            <mat-tab>-->
<!--              <ng-template mat-tab-label>-->
<!--                <mat-icon class="fas fa-chart-pie"></mat-icon>-->
<!--                <h4> Sipariş İstatistikleri </h4>-->
<!--              </ng-template>-->
<!--              <analysis-order-card></analysis-order-card>-->

<!--            </mat-tab>-->

<!--            <mat-tab>-->
<!--              <ng-template mat-tab-label>-->
<!--                <mat-icon class="fas fa-chart-line"></mat-icon>-->
<!--                <h4> Ürün İstatistikleri </h4>-->
<!--              </ng-template>-->
<!--              <analysis-product-card></analysis-product-card>-->
<!--            </mat-tab>-->

<!--            <mat-tab>-->
<!--              <ng-template mat-tab-label>-->
<!--                <mat-icon class="fa fa-users"></mat-icon>-->
<!--                <h4> Müşteri İstatistikleri </h4>-->
<!--              </ng-template>-->

<!--              <analysis-customer-card></analysis-customer-card>-->
<!--            </mat-tab>-->
<!--            <mat-tab>-->
<!--              <ng-template mat-tab-label>-->
<!--                <mat-icon class="fas fa-chart-bar"></mat-icon>-->
<!--                <h4> Paket İstatistikleri </h4>-->
<!--              </ng-template>-->

<!--              <analysis-packet-card></analysis-packet-card>-->
<!--            </mat-tab>-->
<!--          </mat-tab-group>-->
<!--        </div>-->

<!--    </mat-card >-->





<!--      </div>-->




    </div>

</section>

