import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SupportManagementIndexComponent } from './support-management-index/support-management-index.component';
import {SupportManagementRoutingModule} from "./support-management-routing.module";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {NzSelectModule} from "ng-zorro-antd/select";
import {FormsModule} from "@angular/forms";



@NgModule({
  declarations: [
    SupportManagementIndexComponent
  ],
  imports: [
    CommonModule,
    SupportManagementRoutingModule,
    NgxDatatableModule,
    NzSelectModule,
    FormsModule,
  ]
})
export class SupportManagementModule { }
