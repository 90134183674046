import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewsManagementIndexComponent } from './news-management-index/news-management-index.component';
import {NewsManagementRoutingModule} from "./news-management-routing.module";



@NgModule({
  declarations: [
    NewsManagementIndexComponent
  ],
  imports: [
    CommonModule,
    NewsManagementRoutingModule

  ]
})
export class NewsManagementModule { }
