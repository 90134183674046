<form #myForm="ngForm" (ngSubmit)="update === false? adminAdd(): updateAdmin()">
    <div class="grid mb-4 formgrid p-fluid">
        <div class="field mb-4 col-12 md:col-6">
            <label
                    for="name" class="font-medium text-900">Ad</label>
            <input [(ngModel)]="admin.name" type="text" id="name" name="name" pInputText required>
        </div>

        <div class="field mb-4 col-12 md:col-6">
            <label
                    for="surname" class="font-medium text-900">Soyad</label>
                <input [(ngModel)]="admin.surname" name="surname" type="text" id="surname" pInputText required>
        </div>

        <div class="field mb-4 col-12 md:col-6">
            <label
                    for="adminType" class="font-medium text-900">Admin Türü</label>
            <p-dropdown [(ngModel)]="admin.adminType" [options]="cities" name="adminType" id="adminType" optionLabel="name" optionValue="code" [showClear]="false" required></p-dropdown>
        </div>


        <div class="field mb-4 col-12 md:col-6">
            <label
                    for="email" class="font-medium text-900">Email</label>
            <input type="email" [(ngModel)]="admin.email" id="email" name="email" pInputText required>
        </div>
        <div class="field mb-4 col-12 md:col-6">
            <label
                    for="phone" class="font-medium text-900">Telefon</label>
            <p-inputMask [(ngModel)]="admin.phone" mask="(999) 999-9999" id="phone" name="phone" placeholder="(555) 999-9999" required></p-inputMask>
        </div>

        <div class="field mb-4 col-12 md:col-6">
            <label
                    for="isActive" class="font-medium text-900">Şifre</label>
            <div class="p-inputgroup" >
                <input [(ngModel)]="admin.password" [disabled]="update"  name="password" type="text" pInputText placeholder="Şifre" required >
                <button (click)="createPassword()" [disabled]="update" type="button" pButton pRipple icon="pi pi-refresh" styleClass="p-button-warn"></button>
            </div>
        </div>


        <div class="field mb-4 col-12 md:col-6">
            <label
                    for="isActive" class="font-medium text-900">Bayi Kodu</label>

            <input type="text" [(ngModel)]="admin.dealerCode" id="dealerCode" name="dealerCode" pInputText required>

        </div>

        <div class="field mb-4 col-12 md:col-6">
            <label
                    for="isActive" class="font-medium text-900">Aktif</label>
            <p-inputSwitch class="ml-2" [(ngModel)]="admin.isActive" id="isActive" name="isActive"></p-inputSwitch>
        </div>


    </div>





  <div class="row mt-4">
    <div class="col ">
      <p-button *ngIf="!update" class="ml-auto p-toolbar-group-right" type="submit" [disabled]="!myForm.valid" label="Kaydet"></p-button>
      <p-button *ngIf="update" class="ml-auto p-toolbar-group-right" type="submit" [disabled]="!myForm.valid" label="Güncelle"></p-button>
    </div>
  </div>

</form>
