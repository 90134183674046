export class AdminFilterModel{
  email: string;
  adminType: number;
  name: string;
  surname: string;
  phone: string;
  isActive: number;


}
