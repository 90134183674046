import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';
import {AdminModel} from "../../Models/admin-model";
import {AdminFilterModel} from "../../Models/admin-filter-model";

@Injectable({
  providedIn: 'root'
})

export class AdminsService {

  constructor(private http: HttpClient) { }

  getAdmins() {
    return this.http.get(`${environment.localApiUrl}/api/admin/adminList`)
      .pipe(
        map((admins) => {
         return admins;
        })
      );
  }

  addAdmins(admin: AdminModel) {
    return this.http.post(`${environment.localApiUrl}/api/admin/add`, admin)
      .pipe(
        map((admins) => {
          return admins;
        })
      );
  }
  getByEmail(email: string) {
    return this.http.get(`${environment.localApiUrl}/api/admin/getByEmail?email=` + email)
      .pipe(
        map((admins) => {
          return admins;
        })
      );
  }

  updateAdmin(admin: AdminModel){
    return this.http.post( `${environment.localApiUrl}/api/admin/update`, admin).pipe(
      map((res) => {
        return res;
      })
    );
  }

  filterAdmin(admin: AdminFilterModel){
    return this.http.post( `${environment.localApiUrl}/api/admin/filter`, admin).pipe(
      map((res) => {
        return res;
      })
    );
  }

  deleteAdmin(admin: AdminModel){
    return this.http.post(`${environment.localApiUrl}/api/admin/delete`, admin).pipe(
      map((res) => {
         return res;
      })
    );
  }

}
