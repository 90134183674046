import { Component, OnInit } from '@angular/core';
import {AdminsService} from "../../../core/service/admins.service";
import {AdminModel} from "../../../Models/admin-model";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";


@Component({
  selector: 'app-admins-add-update',
  templateUrl: './admins-add-update.component.html',
  styleUrls: ['./admins-add-update.component.sass']
})
export class AdminsAddUpdateComponent implements OnInit {
  createdPassword: string ;
  update = false;
  status = false;
  admin: AdminModel ;
  constructor(private adminsService: AdminsService,
              public ref: DynamicDialogRef,
              public config: DynamicDialogConfig) { }
  cities = [
    {name: 'Kullanıcı', code: 1},
    {name: 'Bayi', code: 2},
    {name: 'Destek', code: 3},
    {name: 'Admin', code: 99},
      ];
    ngOnInit(): void {

      if (this.config.data !== undefined)
      {

        this.update = true;
        this.admin = JSON.parse(JSON.stringify(this.config.data)) ;
        console.log(this.admin);
      }
      else {
        this.admin = new AdminModel();
        this.admin.isActive = true;
      }



  }

  adminAdd = () => {

    this.adminsService.addAdmins(this.admin).subscribe((f: any) => {
      this.status = f.status;
      this.ref.close(this.status);
    });

  }

  updateAdmin = () =>
  {

    this.adminsService.updateAdmin(this.admin).subscribe((f: any) => {
      console.log(f);
      this.status = f.status;
      this.ref.close(this.status);
    });
  }

  createPassword = () =>
  {
   this.admin.password = (Math.random() + 1).toString(36).substring(2);
  }
}

