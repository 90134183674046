import {Component, OnInit} from '@angular/core';


@Component({
  selector: 'analysis-packet-card',
  templateUrl: './analysis-packet-card.component.html',
  styleUrls: ['./analysis-packet-card.component.css']
})


export class AnalysisPacketCardComponent implements OnInit {

  //region expansion panel code

  panelOpenState = false;
  //endregion








  constructor() {
  }

  ngOnInit(): void {
  }


}

