<div class="card">
  <mat-tab-group animationDuration="0ms">
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon></mat-icon>
        <h5> Genel </h5>
      </ng-template>
      <div class="card-body">


        <div style="display: block; width: 80%;">
          <canvas mdbChart
                  [chartType]="chartType"
                  [datasets]="chartDatasets"
                  [labels]="chartLabels"
                  [colors]="chartColors"
                  [options]="chartOptions"
                  [legend]="true"
                  (chartHover)="chartHovered($event)"
                  (chartClick)="chartClicked($event)">
          </canvas>
        </div>
      </div>
    </mat-tab>
    <mat-tab >
      <ng-template mat-tab-label>
        <mat-icon></mat-icon>
        <h5> Günlük </h5>
      </ng-template>
      <div class="card-body">


        <div style="display: block; width: 80%;">
          <canvas mdbChart
                  [chartType]="chartType"
                  [datasets]="chartDatasets"
                  [labels]="chartLabels"
                  [colors]="chartColors"
                  [options]="chartOptions"
                  [legend]="true"
                  (chartHover)="chartHovered($event)"
                  (chartClick)="chartClicked($event)">
          </canvas>
        </div>
      </div>
    </mat-tab>

  </mat-tab-group>


</div>
