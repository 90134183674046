<div class="p-fluid grid mt-4 card p-4" >



  <div class="row">
    <div class="col-12 col-md-6  ">
  <span class=" p-float-label">
  <input [(ngModel)]="order.orderNumber" type="text"
         id="orderNumber" name="taxOffice" pInputText >
  <label for="orderNumber">Sipariş Numarası</label>
    </span>
    </div>
    <div class="col-12 col-md-6 ">
  <span class=" p-float-label">
    <input [(ngModel)]="order.paymentNumber" type="text"
           id="paymentNumber" name="paymentNumber" pInputText required>
  <label for="paymentNumber">Ödeme Numarası</label>
    </span>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-md-6 mt-4 ">
  <span class=" p-float-label">
  <p-dropdown [options]="status" [(ngModel)]="order.status" optionLabel="name" optionValue="code" ></p-dropdown>

    </span>
    </div>
    <div class="col-12 col-md-6 mt-4  ">
  <span class=" p-float-label">
   <p-dropdown [options]="paymentMethod" [(ngModel)]="order.paymentMethod" optionLabel="name" optionValue="code" ></p-dropdown>

    </span>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-md-6 mt-4 ">
  <span class=" p-float-label">
  <p-dropdown [options]="isYearly" [(ngModel)]="order.isYearly" optionLabel="name" optionValue="code" ></p-dropdown>

    </span>
    </div>
    <div class="col-12 col-md-6 mt-4  ">
  <span class=" p-float-label">


    </span>
    </div>
  </div>

  <button pButton type="button" class="mt-5" (click)="saveFilter()" label="Kaydet" ></button>



</div>
