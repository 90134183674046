<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

  <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

  <!-- Position Column -->
  <ng-container matColumnDef="companyName">
    <th mat-header-cell *matHeaderCellDef> Firma Adı </th>
    <td mat-cell *matCellDef="let element"> {{element.companyName}} </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="authorizedPerson">
    <th mat-header-cell *matHeaderCellDef> Yetkili Kişi </th>
    <td mat-cell *matCellDef="let element"> {{element.authorizedPerson}} </td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="startDate">
    <th mat-header-cell *matHeaderCellDef> Başlangıç Tarihi </th>
    <td mat-cell *matCellDef="let element"> {{element.startDate}} </td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="endDate">
    <th mat-header-cell *matHeaderCellDef> Bitiş Tarihi </th>
    <td mat-cell *matCellDef="let element"> {{element.endDate}} </td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="activation">
    <th mat-header-cell *matHeaderCellDef> Aktivasyon Durumu </th>
    <td mat-cell *matCellDef="let element"> {{element.activation}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
