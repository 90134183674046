
<p-confirmPopup></p-confirmPopup>
<p-toolbar styleClass="bg-white my-3 border-0">
  <div class="p-toolbar-group-left">

    <span class="p-input-icon-left mr-2">
      <i class="pi pi-search"></i>
      <input pInputText type="text" (input)="globalFilter($event.target.value)"  placeholder="Arama" />
    </span>


    <button *ngIf="tableTypeEnum.Customer === +tableType || tableTypeEnum.Order== +tableType || tableTypeEnum.Admin== +tableType" pButton pRipple type="button" (click)="detailFilter()" icon="pi pi-filter"  class="mr-2"></button>
    <button *ngIf="filtered" pButton pRipple type="button" icon="pi pi-filter-slash"  (click)="filterClose()" class="p-button-danger"></button>


  </div>

  <div class="p-toolbar-group-right">
    <button *ngIf="tableTypeEnum.Admin === +tableType" (click)="add.emit()" pButton pRipple type="button" label="Admin Ekle" class="p-button-success mr-2"></button>
    <button *ngIf="tableTypeEnum.Host  === +tableType" (click)="add.emit()" pButton pRipple type="button" label="Host Ekle" class="p-button-success mr-2"></button>
    <p-multiSelect [options]="visibilityNameList" [displaySelectedLabel]="false" defaultLabel="Kolon Özelleştir"  [(ngModel)]="selectedVisibility" optionLabel="columnsNameTR" optionValue="columnsNameEN" (onClick)="visibilityChanged($event) " optionDisabled="inactive"></p-multiSelect>

  </div>
</p-toolbar>

<div class=" scrollBarStyle card mt-5">
  <span class="overflow-auto">
  <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8 w-100 ">
    <ng-container *ngFor="let colum of columnsNameEN; index as i;" matColumnDef="{{colum}}">
      <container-element [ngSwitch]="colum">
        <!--select-->
        <span *ngSwitchCase="'Select'">
          <ng-container>
            <th [hidden]="!visibility[colum]"  c mat-header-cell *matHeaderCellDef>
              <mat-checkbox class="mr-2" (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
              </mat-checkbox>
            </th>
            <td [hidden]="!visibility[colum]" mat-cell *matCellDef="let row">
              <mat-checkbox class="mr-2" (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"
                            [aria-label]="checkboxLabel(row)">
              </mat-checkbox>
            </td>
          </ng-container>
        </span>
        <!--Select End -->
        <span *ngSwitchCase="'Edit'">
          <ng-container>
            <th [hidden]="!visibility[colum]"  class="text-center " mat-header-cell *matHeaderCellDef>
              {{columnsNameTR[i]}}</th>
            <td  [hidden]="!visibility[colum]" class="text-center " mat-cell *matCellDef="let element">
              <button pButton pRipple (click)="edit.emit(element)" type="button" icon="pi pi-pencil" class="p-button-rounded p-button-text mx-4"></button>

          </ng-container>
        </span><span *ngSwitchCase="'EditCustomer'">
          <ng-container>
            <th [hidden]="!visibility[colum]"  class="text-center " mat-header-cell *matHeaderCellDef>
              {{columnsNameTR[i]}}</th>
            <td  [hidden]="!visibility[colum]" class="text-center " mat-cell *matCellDef="let element">
              <a routerLink="/musteri/musteri-detay/{{element.id}}">

                  <button pButton pRipple  type="button" icon="pi pi-pencil" class="p-button-rounded p-button-text mx-4"></button>
              </a>


          </ng-container>
        </span>


           <span *ngSwitchCase="'Delete'">
          <ng-container>
            <th [hidden]="!visibility[colum]"  class="text-center " mat-header-cell *matHeaderCellDef>
              {{columnsNameTR[i]}}</th>
            <td  [hidden]="!visibility[colum]" class="text-center " mat-cell *matCellDef="let element">
              <button pButton pRipple (click)="deleteButton($event , element)" type="button" icon="pi pi-trash" class="p-button-rounded p-button-text mx-4"></button>

          </ng-container>
        </span>

              <span *ngSwitchCase="'licenseStatus'">
          <ng-container>
            <th [hidden]="!visibility[colum]"  class="text-center " mat-header-cell *matHeaderCellDef>
              {{columnsNameTR[i]}}</th>
            <td  [hidden]="!visibility[colum]" class="text-center " mat-cell *matCellDef="let element">
               <p-tag *ngIf="element?.remainingTime > 0  " styleClass="mr-2" severity="success" value="Devam Ediyor"> </p-tag>
               <p-tag *ngIf="element?.remainingTime  <= 0 " styleClass="mr-2" severity="danger" value="Süresi Dolmuş"> </p-tag>


          </ng-container>
        </span>


           <span *ngSwitchCase="'companyStatus'">
          <ng-container>
            <th [hidden]="!visibility[colum]"  class="text-center " mat-header-cell *matHeaderCellDef>
              {{columnsNameTR[i]}}</th>
            <td  [hidden]="!visibility[colum]" class="text-center " mat-cell *matCellDef="let element">

              <p-tag *ngIf="element[colum] === 0 || element[colum] === 1"  styleClass="mr-2" value="{{companyStatusName(element[colum])}}"> </p-tag>
              <p-tag *ngIf="element[colum] === 2 " styleClass="mr-2" severity="warning" value="{{companyStatusName(element[colum])}}"> </p-tag>
              <p-tag *ngIf="element[colum] === 3 " styleClass="mr-2" severity="danger" value="{{companyStatusName(element[colum])}}"> </p-tag>


          </ng-container>
        </span>

        <span *ngSwitchCase="'Orderstatus'">
          <ng-container>
            <th [hidden]="!visibility[colum]"  class="text-center " mat-header-cell *matHeaderCellDef>
              {{columnsNameTR[i]}}</th>
            <td  [hidden]="!visibility[colum]" class="text-center " mat-cell *matCellDef="let element">

              <p-tag *ngIf="element.status === 0 "  styleClass="mr-2" value="Bekliyor"> </p-tag>
              <p-tag *ngIf="element.status === 1 " styleClass="mr-2" severity="success" value="Onaylandı"> </p-tag>
              <p-tag *ngIf="element.status === 2 " styleClass="mr-2" severity="danger" value="Reddedildi"> </p-tag>


          </ng-container>
        </span>


             <span *ngSwitchCase="'paymentMethod'">
          <ng-container>
            <th [hidden]="!visibility[colum]"  class="text-center " mat-header-cell *matHeaderCellDef>
              {{columnsNameTR[i]}}</th>
            <td  [hidden]="!visibility[colum]" class="text-center " mat-cell *matCellDef="let element">

              <span class="mx-2" *ngIf="element[colum] === 0 "  styleClass="mr-2"> Kredi Kartı </span>
              <span class="mx-2" *ngIf="element[colum] === 1 "  styleClass="mr-2"> Havale / EFT </span>
              <span class="mx-2" *ngIf="element[colum] === 2 "  styleClass="mr-2"> Diğer </span>


          </ng-container>
        </span>

               <span *ngSwitchCase="'companyName'">
          <ng-container>
            <th [hidden]="!visibility[colum]"  class="text-center " mat-header-cell *matHeaderCellDef>
              {{columnsNameTR[i]}}</th>
            <td [hidden]="!visibility[colum]" class="text-center " mat-cell *matCellDef="let element">
              <span  class="mx-3">  {{element.company.fullname}} </span>


          </ng-container>
        </span>

                  <span *ngSwitchCase="'fullname'">
          <ng-container>
            <th [hidden]="!visibility[colum]"  class="text-center " mat-header-cell *matHeaderCellDef>
              {{columnsNameTR[i]}}</th>
            <td [hidden]="!visibility[colum]" class="text-center " mat-cell *matCellDef="let element">
              <span  class="mx-3">  {{element.fullname === null ? element.name + " " + element.surname :  element.fullname}} </span>


          </ng-container>
        </span>


             <span *ngSwitchCase="'moduleId'">
          <ng-container>
            <th [hidden]="!visibility[colum]"  class="text-center " mat-header-cell *matHeaderCellDef>
              {{columnsNameTR[i]}}</th>
            <td style="min-width: 100px" [hidden]="!visibility[colum]" class="text-center " mat-cell *matCellDef="let element">
              <span  class="mx-3">  Paket: <b>{{element[colum]}} </b> </span>


          </ng-container>
        </span>




             <span *ngSwitchCase="'invoiceNumber'">
          <ng-container>
            <th [hidden]="!visibility[colum]"  class="text-center " mat-header-cell *matHeaderCellDef>
              {{columnsNameTR[i]}}</th>
            <td style="min-width: 100px" [hidden]="!visibility[colum]" class="text-center " mat-cell *matCellDef="let element">
              <span  class="mx-3">

<span *ngIf="element.uuid === null || element.uuid === ''">


              	<button
                        tuiButton
                        type="button"
                        appearance="secondary"
                        class="tui-space_right-3 tui-space_bottom-3"
                        (click)="orderInvoice(element)"
                >
            Faturalandır
            </button>
            </span>
            <span *ngIf="element.uuid !== null" class="flex justify-content-center align-content-center">
                <div tuiGroup class="group">
                      	<button
                                tuiButton
                                (click)="downloadInvoicePdf(element)"
                                type="button"
                                class="tui-group__auto-width-item"
                                appearance="secondary-destructive">
                           <i class="pi pi-file-pdf text-2xl"></i>
                        </button>
                  <tui-island class="island" style="width: 170px; max-width: 170px;">
                    <tui-line-clamp
                            [tuiDropdownLimitWidth]="'min'"

                            [content]="mormont"
                            [lineHeight]="15"
                            [linesLimit]="1.5"
                    ></tui-line-clamp>
                 </tui-island>
                  <ng-template #mormont>
                       <div class="hint no-wrap">
                            {{element.invoiceNumber}}

                             <b   class="hint no-wrap white-space-nowrap">Fatura Tarihi: </b>{{element.InvoiceDate | date: 'dd.MM.yyyy HH:mm:ss'  }}
                       </div>
                  </ng-template>
              </div>
            </span>




                </span>


          </ng-container>
        </span>


           <span *ngSwitchCase="'paymentTotal'">
          <ng-container>
            <th [hidden]="!visibility[colum]"  class="text-center " mat-header-cell *matHeaderCellDef>
              {{columnsNameTR[i]}}</th>
            <td  [hidden]="!visibility[colum]" class="text-center " mat-cell *matCellDef="let element">

             {{element[colum] | currency : 'TRY': ""}} ₺


          </ng-container>
        </span>



              <span *ngSwitchCase="'adminType'">
          <ng-container>
            <th [hidden]="!visibility[colum]"  class="text-center " mat-header-cell *matHeaderCellDef>
              {{columnsNameTR[i]}}</th>
            <td  [hidden]="!visibility[colum]" class="text-center " mat-cell *matCellDef="let element">

              <span *ngIf="+element[colum] === 99">Admin</span>
              <span *ngIf="+element[colum] === 1">Kullanıcı</span>
              <span *ngIf="+element[colum] === 2">Bayi</span>
              <span *ngIf="+element[colum] === 3">Destek</span>
              <span *ngIf="+element[colum] === 0">Hatalı Kod</span>
            </td>

          </ng-container>
        </span>


              <span *ngSwitchCase="'selectedMarketplaces'">
          <ng-container>
            <th [hidden]="!visibility[colum]"  class="text-center " mat-header-cell *matHeaderCellDef>
              {{columnsNameTR[i]}}</th>
            <td  [hidden]="!visibility[colum]" class="text-center " mat-cell *matCellDef="let element">

              <span *ngFor="let marketplace of element[colum]">
<!--                <img width="75px" src="{{marketplace?.icon}}" >-->
                <span class="mr-2">{{marketplace.name}}</span>
              </span>
            </td>

          </ng-container>
        </span>

                <span *ngSwitchCase="'selectedSpecialCargo'">
          <ng-container>
            <th [hidden]="!visibility[colum]"  class="text-center " mat-header-cell *matHeaderCellDef>
              {{columnsNameTR[i]}}</th>
            <td  [hidden]="!visibility[colum]" class="text-center " mat-cell *matCellDef="let element">



                <span class="mr-2">{{element[colum]?.name}}</span>

            </td>

          </ng-container>
        </span>






        <span *ngSwitchDefault>
          <ng-container>
            <th [hidden]="!visibility[colum]" class="text-center px-2 " mat-header-cell *matHeaderCellDef>{{columnsNameTR[i]}}</th>
            <td [hidden]="!visibility[colum]" class="text-center px-2" mat-cell *matCellDef="let element"> <span class="mx-2">
              <ng-container [ngSwitch]="defaultColumn(element[colum])">
                <span *ngSwitchCase="'boolean'">
                  {{element[colum] === true ? "Evet" : "Hayır"}}
                </span>
                  <span *ngSwitchCase="'Date'">
              {{element[colum]| date:"dd/MM/YYYY"}}

                  </span>
                <span *ngSwitchCase="'string'">
                  <span pTooltip="{{element[colum].length > 40 ? element[colum]: ''}}">
                    {{element[colum].length > 40 ? slinceString(element[colum]): element[colum]}}</span>
                  </span>

                <span *ngSwitchDefault>
              {{defaultColumn(element[colum])}}
<!--                  {{element[colum]}}-->
                </span>


              </ng-container>

            </span></td>
          </ng-container>
        </span>
      </container-element>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columnsNameEN"></tr>
    <tr mat-row *matRowDef="let myRowData; columns: columnsNameEN"></tr>

  </table>
    </span>
  <mat-paginator #paginator [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize "
                 [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)" aria-label="Select page">
  </mat-paginator>
</div>



<ng-template [(tuiPdfViewer)]="pdfOpen">
    <iframe
            width="100%"
            height="100%"
            title="pdf"
            class="iframe"
            [src]="pdfSrc"
    ></iframe>
</ng-template>


