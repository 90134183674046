<div class="card">

  <div class="card-body">

    <mat-accordion>
      <mat-expansion-panel (opened)="panelOpenState = true"
                           (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            En çok hangi paket satılıyor?
          </mat-panel-title>
          <mat-panel-description>
            Lisans paketlerinde satılma sıralamasını gösterir.
          </mat-panel-description>
        </mat-expansion-panel-header>
    <licence-packet-chart></licence-packet-chart>

      </mat-expansion-panel>

      <mat-expansion-panel (opened)="panelOpenState = true"
                           (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Bayilere göre hangi paketler satılıyor?
          </mat-panel-title>
          <mat-panel-description>
           Bayilere göre satılan paketlerin sıralamasını gösterir.
          </mat-panel-description>
        </mat-expansion-panel-header>
        <dealer-packet-chart></dealer-packet-chart>
      </mat-expansion-panel>

      <mat-expansion-panel (opened)="panelOpenState = true"
                           (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
           Sektörlere göre hangi paketler satılıyor?
          </mat-panel-title>
          <mat-panel-description>
            Sektörlere göre satılan paketlerin sıralamasını gösterir.
          </mat-panel-description>
        </mat-expansion-panel-header>
<sector-packet-chart></sector-packet-chart>
      </mat-expansion-panel>

      <mat-expansion-panel (opened)="panelOpenState = true"
                           (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Paketlerin yıllık ve aylık alım oranları?
          </mat-panel-title>
          <mat-panel-description>
            Lisans paketlerinin yıllık ve aylık alım durumlarını gösterir.
          </mat-panel-description>
        </mat-expansion-panel-header>
<rate-packet-chart></rate-packet-chart>
      </mat-expansion-panel>
    </mat-accordion>

  </div>
</div>
