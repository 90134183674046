import {Component, Input, Output, EventEmitter, OnInit, AfterViewInit, AfterContentInit, Inject} from '@angular/core';
import {TableTypeEnum} from "../enums/table-type-enum";
import {MatTableDataSource} from "@angular/material/table";
import {SelectionModel} from "@angular/cdk/collections";
import {PageEvent} from "@angular/material/paginator";
import {
  GeneralTableColumnsGeneratorEnum,
  GeneralTableVisibilityColumnsGeneratorEnum
} from "../enums/general-table-columns-generator-enum";
import {InstanceService} from "../../../core/service/instance.service";
import {InstanceResponseModel} from "../../../core/models/instance-response-model";
import {AdminsResponseModel} from "../../../core/models/admins-response-module";
import {AdminsService} from "../../../core/service/admins.service";
import {OrdersService} from "../../../core/service/orders.service";
import {PagesResponseModel} from "../../../core/models/pages-response-model";
import {PagesService} from "../../../core/service/pages.service";
import {CompanyService} from "../../../core/service/company.service";
import {CustomerFilterComponent} from "../../customer-management/customer-filter/customer-filter.component";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {ConfirmationService, MessageService} from "primeng/api";
import {MatDialog} from "@angular/material/dialog";
import {PageModel} from "../../../Models/page-model";
import {CompanyModel} from "../../../Models/company-model";
import {CompanyListResponseModel} from "../../../Models/company-list-response-model";
import {OrdersFilterComponent} from "../../orders-management/orders-filter/orders-filter.component";
import {AdminsFilterComponent} from "../../admins-management/admins-filter/admins-filter.component";
import {jsGlobalObjectValue} from "@angular/compiler-cli/src/ngtsc/partial_evaluator/src/known_declaration";

import {CompanyStatusEnum, CompanyStatusEnumTR} from "../../../enums/companyStatus-enum";
import {Router} from "@angular/router";
import {isEmpty} from "rxjs";
import {DomSanitizer} from "@angular/platform-browser";


@Component({
  selector: 'app-general-table',
  templateUrl: './general-table.component.html',
  styleUrls: ['./general-table.component.scss']
})
export class GeneralTableComponent implements OnInit, AfterContentInit {
  @Input() tableType: number;
  // @Input()  detailFilter: any;
  @Output() edit = new EventEmitter();
  @Output() delete = new EventEmitter();
  @Output() selected = new EventEmitter();
  @Output() add = new EventEmitter();
  columnsNameEN: any;

  pdfOpen = false;
  pdfSrc: any;
  columnsNameTR: any;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  selection = new SelectionModel<any>(true, []);
  totalRows = 1;
  currentPage = 0;
  pageSize = 25;
  pageSizeOptions: number[] = [25, 50, 100];
  tableName: string;
  visibility: any;
  visibilityNameList: any[] = [];
  selectedVisibility: any[] = [];
  ref: DynamicDialogRef;
  filtered = false;
  filterModel: any;
  filterCompanent: any;
  filterValue: string;
  textFiltered = false;
  timeOutId: any;

  public get tableTypeEnum(): typeof TableTypeEnum {
    return TableTypeEnum;
  }

  constructor(private instanceService: InstanceService,
              private adminsService: AdminsService,
              private ordersService: OrdersService,
              private pagesService: PagesService,
              private companyService: CompanyService,
              public dialogService: DialogService,
              public messageService: MessageService,
              private router: Router,
              @Inject(DomSanitizer) private readonly sanitizer: DomSanitizer,
              private confirmationService: ConfirmationService,
              private dialog: MatDialog) {
  }

  // cities = [
  //   {columnsNameTR: 'New York', columnsNameEN: 'NY', visibility: true},
  //   {columnsNameTR: 'Rome', columnsNameEN: 'RM', visibility: true},
  //   {columnsNameTR: 'London', columnsNameEN: 'LDN', visibility: false},
  //   {columnsNameTR: 'Istanbul', columnsNameEN: 'IST', visibility: true},
  //   {columnsNameTR: 'Paris', columnsNameEN: 'PRS', visibility: false}
  // ];
  // selectedCities: any;
  responsiveOptions = [
    {
      breakpoint: '1024px',
      numVisible: 3,
      numScroll: 3
    },
    {
      breakpoint: '768px',
      numVisible: 2,
      numScroll: 2
    },
    {
      breakpoint: '560px',
      numVisible: 1,
      numScroll: 1
    }
  ];

  companyStatusName = (status) => CompanyStatusEnumTR[CompanyStatusEnum[status]];

  ngOnInit(): void {
    const today = new Date();


    if (this.tableType !== undefined) {
      let colum = GeneralTableColumnsGeneratorEnum[TableTypeEnum[+this.tableType]];
      this.columnsNameEN = Object.keys(colum);
      this.columnsNameTR = Object.values(colum);
      this.tableName = TableTypeEnum[+this.tableType];
      this.request();
      this.createVisibility();
    }


  }


  ngAfterContentInit() {
    this.request();
    this.createVisibility();
  }

  visibilityChanged = (event: any) => {
    if (this.selectedVisibility !== null && this.selectedVisibility !== []) {
      this.columnsNameEN.forEach(f => this.visibility[f] = false);
      this.selectedVisibility.forEach(f => this.visibility[f] = true);
    }
  }

  createVisibility = () => {

    const defaulFalselist = JSON.parse(JSON.stringify(GeneralTableVisibilityColumnsGeneratorEnum[TableTypeEnum[this.tableType]]));
    this.visibility = JSON.parse(JSON.stringify(GeneralTableColumnsGeneratorEnum[TableTypeEnum[this.tableType]]));
    this.columnsNameEN.forEach((f: any) => {
      this.visibilityNameList.push({columnsNameTR: this.visibility[f], columnsNameEN: f});
      if (defaulFalselist.indexOf(f) !== -1) {
        this.visibility[f] = false;
      } else {
        this.selectedVisibility.push(f);
        this.visibility[f] = true;
      }
    });
  }


  request = () => {

    if (this.filtered) {
      this.FilterRequest();
    } else if (this.textFiltered) {
      clearTimeout(this.timeOutId);
      this.timeOutId = setTimeout(() => {
        this.companyService.filterTextCompany(this.filterValue, this.currentPage, this.pageSize).subscribe((res) => {
          this.dataSource = new MatTableDataSource(res.items);
        });
      }, 500);

    } else {
      this.noFilterRequest();
    }

  }
  FilterRequest = () => {
    switch (this.tableName) {
      case "Host":
        this.instanceService.getInstance().subscribe((res: InstanceResponseModel) => {
          this.dataSource = new MatTableDataSource(res.data);

        });
        break;
      case "Admin":
        this.adminsService.filterAdmin(this.filterModel).subscribe((res: any) => {
          this.dataSource = new MatTableDataSource(res.data);

        });
        break;
      case "Order":
        this.ordersService.filterOrders(this.filterModel).subscribe((res: any) => {
          this.dataSource = new MatTableDataSource(res.data);

        });
        break;
      case "Page":
        this.pagesService.getPages().subscribe((res: PagesResponseModel) => {
          this.dataSource = new MatTableDataSource(res.data);


        });
        break;
      case "Customer":

        this.companyService.filterCompanyList({
          Filter: this.filterModel,
          IsPaging: true,
          Page: this.currentPage,
          PageSize: this.pageSize
        }).subscribe((res: any) => {
          console.log(res);
          this.dataSource = new MatTableDataSource(res.items);
          this.totalRows = res.totalCount;


        });

        break;
    }
  }

  orderInvoice = (element: any) => {
    this.confirmationService.confirm({
      target: event.target,
      message: 'Faturalandırmak istediğinize emin misiniz?',
      icon: 'pi pi-file-pdf',
      accept: () => {
        this.ordersService.sendInvoice(element.id).subscribe((res: any) => {
          this.request();
        });

      },
      reject: () => {
        // reject action
      }
    });

}
  downloadInvoicePdf = (element: any) => {
    this.ordersService
        .GetInvoicePdf(element.uuid, element.invoiceType)
        .subscribe((response: any) => {

            const byteCharacters = atob(response.data);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: "application/pdf" });
            this.pdfSrc = this.sanitizer.bypassSecurityTrustResourceUrl(
                URL.createObjectURL(blob)
            );

            this.pdfOpen = true;
        });
  }

  noFilterRequest = () => {

    switch (this.tableName) {
      case "Host":
        this.instanceService.getInstance().subscribe((res: InstanceResponseModel) => {
          this.dataSource = new MatTableDataSource(res.data);

        });
        break;
      case "Admin":
        this.filterCompanent = AdminsFilterComponent;
        this.adminsService.getAdmins().subscribe((res: any) => {
          this.dataSource = new MatTableDataSource(res.data);

        });
        break;
      case "Order":
        console.log("order Çalıştı");
        this.filterCompanent = OrdersFilterComponent;
        this.ordersService.getOrders().subscribe((res: any) => {
          this.dataSource = new MatTableDataSource(res.data);

        });
        break;
      case "Page":
        console.log("page Çalıştı");
        this.pagesService.getPages().subscribe((res: PageModel[]) => {
          this.dataSource = new MatTableDataSource(res);


        });
        break;
      case "Customer":

        this.filterCompanent = CustomerFilterComponent;
        this.companyService.companyList({
          Page: this.currentPage,
          PageSize: this.pageSize
        }).subscribe((res: CompanyListResponseModel) => {
          this.dataSource = new MatTableDataSource(res.items);
          this.totalRows = res.totalCount;
          console.log(res);

        });
        break;
    }
  }

  defaultColumn = (value: any) => {
    const dateCheck: any = new Date(value).toString();
    if (typeof value === "boolean") {
      return "boolean";
    }
    if (typeof value === "string" && value.length > 10 && dateCheck !== "Invalid Date" && value[10] === "T") {
      return "Date";
    }
    if (typeof value === "string") {
      return "string";
    }

    return value;
  }

  deleteButton = (event: any, element) => {

    this.confirmationService.confirm({
      target: event.target,
      message: 'Silmek istediğinize emin misiniz?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.delete.emit(element);
        setTimeout(() => {
          this.request();
        }, 200);

      },
      reject: () => {
        // reject action
      }
    });

  }


  detailFilter = () => {


    this.ref = this.dialogService.open(this.filterCompanent, {
      header: 'Müşteri Filtreleme',
      contentStyle: {"max-height": "auto !important", overflow: "auto"},
      baseZIndex: 10000

    });

    this.ref.onClose.subscribe((filterModel: any) => {
      console.log("aaaa" + filterModel);
      if (filterModel === false || filterModel === undefined) {
        console.log();
        this.filtered = false;
      } else {
        this.filtered = true;
        this.currentPage = 0;
        this.pageSize = 25;
        this.filterModel = filterModel;
        this.request();
      }

    });
  }


  filterClose = () => {
    this.filtered = false;
    this.currentPage = 0;
    this.pageSize = 25;
    this.request();

  }

  globalFilter = (filterValue: string) => {
    console.log(filterValue);
    this.filterValue = filterValue.trim();
    this.filterValue = filterValue.toLowerCase();
    console.warn(this.filterValue);
    if (this.tableName === "Customer") {
      this.currentPage = 0;
      this.pageSize = 25;
      if (this.filterValue !== "" && this.filterValue !== undefined && this.filterValue !== null) {
        this.textFiltered = true;
      } else {
        this.textFiltered = false;

      }
      console.log(this.textFiltered);
      this.request();

    } else {
      this.dataSource.filter = filterValue;
    }

  }

  slinceString = (value: string) => value.slice(0, 40);


  pageChanged(event: PageEvent) {
    console.log(event);
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    // this.getPagedata();
    this.request();
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  checkboxLabel(row?: any): string {
    return !row
      ? `${this.isAllSelected() ? 'deselect' : 'select'} all`
      : `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected == numRows;
  }

}
