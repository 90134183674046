export class AdminsModule {
    Id: number;
    Email: string;
    Password: string;
    AdminType: number;
    Name: string;
    Surname: string;
    DealerCode: string;
    Phone: string;
    IsActive: boolean;
}