<!-- <div class="" >
  <mat-form-field appearance="standard">
    <i class="fas fa-search"></i>
    <mat-label>Genel Arama</mat-label>
   <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>
  </mat-form-field>
</div> -->

<div class="mat-elevation-z8">
    <table style="width: 100%;" mat-table [dataSource]="data">

        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> id </th>
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>

        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef> email </th>
            <td mat-cell *matCellDef="let element"> {{element.email}} </td>
        </ng-container>

        <ng-container matColumnDef="password">
            <th mat-header-cell *matHeaderCellDef> password </th>
            <td mat-cell *matCellDef="let element">{{element.password}}</td>
        </ng-container>

        <ng-container matColumnDef="AdminType">
            <th mat-header-cell *matHeaderCellDef> AdminType </th>
            <td mat-cell *matCellDef="let element">{{element.AdminType}} </td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> name </th>
            <td mat-cell *matCellDef="let element">{{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="surname">
            <th mat-header-cell *matHeaderCellDef> surname </th>
            <td mat-cell *matCellDef="let element">{{element.surname}} </td>
        </ng-container>

        <ng-container matColumnDef="dealerCode">
            <th mat-header-cell *matHeaderCellDef> dealerCode </th>
            <td mat-cell *matCellDef="let element">{{element.dealerCode}} </td>
        </ng-container>

        <ng-container matColumnDef="phone">
            <th mat-header-cell *matHeaderCellDef> phone </th>
            <td mat-cell *matCellDef="let element">{{element.phone}} </td>
        </ng-container>

        <ng-container matColumnDef="isActive">
            <th mat-header-cell *matHeaderCellDef> isActive </th>
            <td mat-cell *matCellDef="let element">{{element.isActive}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Select page of periodic elements">
    </mat-paginator>

</div>