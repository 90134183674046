import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {CompanyFilterModel} from "../../../core/models/company-filter-model";
import {CompanyService} from "../../../core/service/company.service";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {JustOkayComponent} from "../../helper-components/just-okay/just-okay.component";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";

@Component({
  selector: 'app-customer-filter',
  templateUrl: './customer-filter.component.html',
  styleUrls: ['./customer-filter.component.sass']
})
export class CustomerFilterComponent implements OnInit {
  // generalForm = new UntypedFormGroup({
  //   title: new UntypedFormControl(''),
  //   email: new UntypedFormControl(''),
  //   fullname: new UntypedFormControl(''),
  //   province: new UntypedFormControl(''),
  //   licenseType: new UntypedFormControl('0'),
  //   companyStatus: new UntypedFormControl('0'),
  //   listType: new UntypedFormControl('0'),
  //   isActive: new UntypedFormControl('0')
  // });


  company: CompanyFilterModel = new CompanyFilterModel();
  companyStatus = [
    {name: 'Lisans Durumu', code: ""},
    {name: 'Demo', code: "1"},
    {name: 'Lisanslı', code: "2"},
    {name: 'Süresi Dolmuş', code: "3"},
  ];
  licenseType = [
    {name: 'Lisans Süresi', code: ""},
    {name: '1 Ay Kalanlar', code: "1"},
    {name: '1 Hafta Kalanlar', code: "2"},
    {name: 'Süresi Dolanlar', code: "3"},
    {name: 'Demo Aktifler', code: "4"},
  ];

  isActive = [
    {name: 'Üyelik Durumu', code: 0},
    {name: 'Aktif', code: 1},
    {name: 'Pasif', code: 2},

  ];
  listType = [
    {name: 'Listeleme Tipi ', code: "0"},
    {name: 'Demo', code: "1"},
    {name: 'Lisanslı', code: "2"},

  ];

  constructor(public ref: DynamicDialogRef ) {
  }

  ngOnInit(): void {

  }

  saveFilter() {

    const defaultFilter = {
      email: "",
      title: "",
      isActive: 0,
      province: "",
      companyStatus: "0",
      name: "",
      surname: "",
      licenseType: "0",
      listType: "0"
    };
    if (JSON.stringify(this.company) === JSON.stringify(defaultFilter)) {
      this.ref.close(false);
    } else {
      this.ref.close(this.company);
    }

  }
}
