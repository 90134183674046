<form #myForm="ngForm" (ngSubmit)="updateCompany()">
<div class="p-fluid grid mt-4 card p-4" >

  <div class="row">
    <div class="col-12 mt-5 ">
  <span class=" p-float-label">
  <input [(ngModel)]="company.title" type="text" id="title" name="title" pInputText required>
  <label for="title">Şirket Adı</label>
    </span>
    </div>
  </div>

    <div class="row">
    <div class="col-12 mt-5 ">
  <span class=" p-float-label">
  <input readonly [(ngModel)]="company.code" type="text" id="code" name="code" pInputText required>
  <label for="code">Kod</label>
    </span>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-md-6 mt-5 ">
  <span class=" p-float-label">
  <input [(ngModel)]="company.taxOffice" type="text" class="ng-dirty"
         id="taxOffice" name="taxOffice" pInputText required>
  <label for="taxOffice">Vergi Dairesi</label>
    </span>
    </div>
    <div class="col-12 col-md-6 mt-5">
  <span class=" p-float-label">
    <p-inputNumber [(ngModel)]="company.taxNumber"  [useGrouping]="false" class="ng-dirty"
                   name="taxNumber" maxlength="10" id="taxNumber" required ></p-inputNumber>
  <label for="taxNumber">Vergi Numarası</label>
    </span>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-md-6 mt-5 ">
  <span class=" p-float-label">
     <textarea id="address" [(ngModel)]="company.address" rows="2" name="address" class="ng-dirty" cols="30" pInputTextarea
               required></textarea>
   <label for="address">Adres</label>
    </span>
    </div>

    <div class="col col-md-3 mt-5 pt-4">
  <span class=" p-float-label">
  <input [(ngModel)]="company.province" type="text" id="province" name="province" class="ng-dirty" pInputText required>
  <label for="province">İl</label>
    </span>
    </div>

    <div class="col col-md-3 mt-5 pt-4 ">
  <span class=" p-float-label">
  <input [(ngModel)]="company.district" type="text" id="district" name="district" class="ng-dirty" pInputText required>
  <label for="district">İlçe</label>
    </span>
    </div>


  </div>

  <div class="row">
    <div class="col-12 col-md-3 mt-5 ">
  <span class=" p-float-label">
  <input [(ngModel)]="company.fullname" type="text" id="fullname"
         class="ng-dirty" name="fullname" pInputText required >
  <label for="fullname">Yetkili Kişi</label>
    </span>
    </div>

    <div class="col-12 col-md-3 mt-5">
  <span class=" p-float-label">
  <input [(ngModel)]="company.email" name="email" class="ng-dirty"
         type="text" id="email" pInputText required>
  <label for="email">Email</label>
    </span>
    </div>


    <div class="col-12 col-md-3 mt-5">
  <span class=" p-float-label">
     <p-inputMask mask="(999) 999-9999" [(ngModel)]="company.phone" class="ng-dirty"
                  name="phone" type="text" id="phone" placeholder="(999) 999-9999" required></p-inputMask>

  <label for="phone">Telefon</label>
    </span>
    </div>

    <div class="col-12 col-md-3 mt-5">
  <span class=" p-float-label">
    <p-inputNumber [(ngModel)]="company.identity" class="ng-dirty"
                   [useGrouping]="false" name="identity" maxlength="11" id="identity" required ></p-inputNumber>

  <label for="identity">Kimlik Numarası</label>
    </span>
    </div>

  </div>

  <div class="row">
    <div class="col-12 col-md-3 mt-5 ">
  <span class=" p-float-label">
     <input [ngModel]="company.createdDate  | date: 'dd/MM/y' " [disabled]="true"
            name="createdDate" type="text" id="createdDate" class="ng-dirty" pInputText required>
    <label for="createdDate">Oluşturma Tarihi</label>
    </span>
    </div>

    <div class="col-12 col-md-3 mt-5">
  <span class=" p-float-label">
    <input [ngModel]="company.expireDate  | date: 'dd/MM/y' " [disabled]="true"
           name="expireDate" type="text" id="expireDate" class="ng-dirty" pInputText required>
<!--    <p-calendar [ngModel]="company.expireDate" dateFormat="dd/mm/yy" class="ng-dirty"-->
<!--                inputId="dateformat" name="expireDate" id="expireDate"></p-calendar>-->
 <label for="expireDate">Bitiş Tarihi</label>
    </span>
    </div>

    <div class="col-12 col-md-3 mt-5 ">
  <span class=" p-float-label">
        <input [ngModel]="company.supportExpireDate  | date: 'dd/MM/y' " [disabled]="true"
               name="supportExpireDate" type="text" id="supportExpireDate" class="ng-dirty" pInputText required>
<!--      <p-calendar [ngModel]="company.supportExpireDate"-->
<!--                  class="ng-dirty"-->
<!--                  dateFormat="dd/mm/yy" inputId="dateformat" name="supportExpireDate" id="supportExpireDate"></p-calendar>-->
 <label for="supportExpireDate">Destek Bitiş Tarihi</label>
    </span>
    </div>

    <div class="col-12 col-md-3 mt-5 ">
  <span class=" p-float-label">
        <input [ngModel]="company.rememberDate  | date: 'dd/MM/y' " [disabled]="true"
               name="rememberDate" type="text" id="rememberDate" class="ng-dirty" pInputText required>
<!--     <input [ngModel]="company.rememberDate  " [disabled]="true"-->
<!--            name="createdDate" type="text" id="rememberDate"-->
<!--            class="ng-dirty" pInputText required>-->
    <label for="createdDate">Hatırlatma Tarihi</label>
    </span>
    </div>


  </div>

  <div class="row">
    <div class="col-12 col-md-3 mt-5 ">
  <span class=" p-float-label ">
  <input [(ngModel)]="company.referenceCode" class="ng-dirty" type="text" id="referenceCode" name="referenceCode" [ngClass]="" pInputText  >

  <label for="referenceCode">Bayi Referans Kodu</label>
    </span>

    </div>

    <div class="col-12 col-md-3 mt-5 " >
      <div class="mx-auto row mt-2"><span class="mr-2 ml-auto"> Aktif</span>
        <p-inputSwitch  class="mr-auto ng-dirty"  name="isActive" [(ngModel)]="company.isActive"></p-inputSwitch></div>
    </div>


    <div class="col-12 col-md-3 mt-5 " >
      <div class="mx-auto row mt-2"><span class="mr-2 ml-auto"> Müşteri Takibi</span>
        <p-inputSwitch  class="mr-auto ng-dirty"  name="customerTracking" [(ngModel)]="company.customerTracking"></p-inputSwitch></div>
    </div>


    <div class="col-12 col-md-3 mt-5 " >
      <div class="mx-auto row mt-2"><span class="mr-2 ml-auto"> Email Aktivasyon</span>
        <p-inputSwitch disabled="true"  name="emailActivation" class="mr-auto ng-dirty"  [(ngModel)]="company.emailActivation"></p-inputSwitch></div>
    </div>

  </div>

  <div class="row">
    <div class="col-12  mt-5 ">
  <span class=" p-float-label">
     <textarea id="customNote" [(ngModel)]="company.customNote" rows="5" name="customNote"  cols="30" pInputTextarea
               required></textarea>
   <label for="customNote">Not</label>
    </span>
    </div>
  </div>

  <div class="row">
    <div class="col col-md-11 mt-5"></div>
    <div class="col col-md-1 mt-5">
      <p-button class="ml-auto p-toolbar-group-right" type="submit"  label="Güncelle"></p-button>
    </div>
  </div>

</div>
</form>

