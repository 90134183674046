<div class="card">

      <div class="card-body">

        <div class="row">
          <div class="col-sm text-center">
            <nz-progress [nzPercent]="30" nzType="circle" [nzWidth]="80"></nz-progress>
            <button mat-raised-button color="accent">Yeni Üyeler</button>
          </div>
          <div class="col-sm text-center">
            <nz-progress [nzPercent]="5" nzType="circle" [nzWidth]="80"></nz-progress>
            <button mat-raised-button color="accent">Yeni Bayi Üyeleri</button>
          </div>
          <div class="col-sm text-center">
            <nz-progress [nzPercent]="60" nzType="circle" [nzWidth]="80"></nz-progress>
            <button mat-raised-button color="accent">Sektörlere Göre Üyeler</button>
          </div>
        </div>
        <div class="col-lg" style="margin-top: 30px;">
          <analysis-table></analysis-table>
        </div>



      </div>


</div>
