import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class InstanceService {

  constructor(private http: HttpClient) { }

  getInstance() {
    return this.http.get(`${environment.localApiUrl}/api/instance/instanceList`)
      .pipe(
        map((instance) => {
          console.log(instance);
          return instance;
        })
      );
  }

  addInstance(instance: any) {
    return this.http.post(`${environment.localApiUrl}/api/instance/createInstance`, instance)
      .pipe(
        map((result) => {
                  return result;
        })
      );
  }
  updateInstance(instance: any) {
    return this.http.post(`${environment.localApiUrl}/api/instance/update`, instance)
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  deleteInstance(instance: any) {
    return this.http.post(`${environment.localApiUrl}/api/instance/delete`, instance)
      .pipe(
        map((result) => {
          return result;
        })
      );
  }
}
