<div class="p-fluid grid mt-4 card p-4" >



  <div class="row">
    <div class="col-12 col-md-6  ">
  <span class=" p-float-label">
  <input [(ngModel)]="admin.name" type="text"
         id="name" name="name" pInputText >
  <label for="name">Ad</label>
    </span>
    </div>
    <div class="col-12 col-md-6 ">
  <span class=" p-float-label">
    <input [(ngModel)]="admin.surname" type="text"
           id="surname" name="surname" pInputText >
  <label for="surname">Soyad</label>
    </span>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-md-6 mt-4 ">
  <span class=" p-float-label">
  <input [(ngModel)]="admin.email" type="text"
         id="email" name="email" pInputText >
  <label for="email">Email</label>
    </span>
    </div>
    <div class="col-12 col-md-6 mt-4">
  <span class=" p-float-label">
     <p-inputMask [(ngModel)]="admin.phone" mask="(999) 999-9999" id="phone" name="phone" placeholder="(555) 999-9999" required></p-inputMask>

  <label for="phone">Telefon</label>
    </span>
    </div>
  </div>

    <div class="row">
    <div class="col-12 col-md-6 mt-4 ">
  <span class=" p-float-label">
  <p-dropdown [options]="adminType" [(ngModel)]="admin.adminType" optionLabel="name" optionValue="code" ></p-dropdown>

    </span>
    </div>
    <div class="col-12 col-md-6 mt-4  ">
  <span class=" p-float-label">
   <p-dropdown [options]="isActive" [(ngModel)]="admin.isActive" optionLabel="name" optionValue="code" ></p-dropdown>

    </span>
    </div>
  </div>

<!--  <div class="row">-->
<!--    <div class="col-12 col-md-6 mt-4 ">-->
<!--  <span class=" p-float-label">-->
<!--  <p-dropdown [options]="isYearly" [(ngModel)]="order.isYearly" optionLabel="name" optionValue="code" ></p-dropdown>-->

<!--    </span>-->
<!--    </div>-->
<!--    <div class="col-12 col-md-6 mt-4  ">-->
<!--  <span class=" p-float-label">-->


<!--    </span>-->
<!--    </div>-->
<!--  </div>-->

  <button pButton type="button" class="mt-5" (click)="saveFilter()" label="Kaydet" ></button>



</div>
