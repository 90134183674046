<div style="overflow-x:auto;">
<table class="col-md-12" mat-table [dataSource]="dataSource" matSort  >

  <ng-container matColumnDef="select" >
    <th [ngStyle]="{'display': visibility.select ? '' : 'none' }"  mat-header-cell *matHeaderCellDef>
      <mat-checkbox
                    (click)="selectedChange()"
                    (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()">
      </mat-checkbox>
    </th>
    <td [ngStyle]="{'display': visibility.select ? '' : 'none' }"  mat-cell *matCellDef="let row">
      <mat-checkbox (click)="$event.stopPropagation();selectedChange(); "
                    (change)="$event ? selection.toggle(row) : null"
                    [checked]="selection.isSelected(row)">
      </mat-checkbox>
    </td>
  </ng-container>


<ng-container  class="col" matColumnDef="id">
  <th [ngStyle]="{'display': visibility.id ? '' : 'none' }" mat-header-cell *matHeaderCellDef mat-sort-header> Detay</th>
  <td [ngStyle]="{'display': visibility.id ? '' : 'none' }" mat-cell *matCellDef="let row">
    <button class="mat-button" matTooltip="Düzenle" (click)="editCompany(row)">
      <span class="material-icons-outlined" style="color: #007bff">edit</span>
    </button>
    <!--  <button class="mat-button" [routerLink]="['musteri/musteri-detay/', this.row.id]" [queryParams]="{row: row}">
        <span class="material-icons-outlined" style="color: #007bff">edit</span>
      </button>-->
  </td>
</ng-container>

<!-- support Column -->
<ng-container class="col" matColumnDef="remoteSupportPermission">
  <th [ngStyle]="{'display': visibility.remoteSupportPermission ? '' : 'none' }" mat-header-cell *matHeaderCellDef mat-sort-header> Uzak Bağlantı</th>
  <td [ngStyle]="{'display': visibility.remoteSupportPermission ? '' : 'none' }" mat-cell *matCellDef="let row">
    <p class="support" *ngIf="row.remoteSupportPermission" style="color: #006400 ">Açık</p>
    <p class="support" *ngIf="!row.remoteSupportPermission" style="color: #8b0000 ">Kapalı</p>
  </td>
</ng-container>

<!-- title Column -->
<ng-container class="col" matColumnDef="title">
  <th [ngStyle]="{'display': visibility.title ? '' : 'none' }" mat-header-cell *matHeaderCellDef mat-sort-header> Firma Adı</th>
  <td [ngStyle]="{'display': visibility.title ? '' : 'none' }" mat-cell *matCellDef="let row"> {{row.title}} </td>
</ng-container>

<!-- referenceCode Column -->
<ng-container class="col" matColumnDef="referenceCode">
  <th [ngStyle]="{'display': visibility.referenceCode ? '' : 'none' }" mat-header-cell *matHeaderCellDef mat-sort-header> Referans Kod</th>
  <td [ngStyle]="{'display': visibility.referenceCode ? '' : 'none' }" mat-cell *matCellDef="let row"> {{row.referenceCode}} </td>
</ng-container>

<!-- fullname Column -->
<ng-container class="col" matColumnDef="fullname">
  <th [ngStyle]="{'display': visibility.fullname ? '' : 'none' }" mat-header-cell *matHeaderCellDef mat-sort-header> Yetkili Kişi</th>
  <td [ngStyle]="{'display': visibility.fullname ? '' : 'none' }" mat-cell *matCellDef="let row"> {{row.fullname}} </td>
</ng-container>

<!-- email Column -->
<ng-container class="col" matColumnDef="email">
  <th [ngStyle]="{'display': visibility.email ? '' : 'none' }" mat-header-cell *matHeaderCellDef mat-sort-header> Email</th>
  <td [ngStyle]="{'display': visibility.email ? '' : 'none' }" mat-cell *matCellDef="let row"> {{row.email}} </td>
</ng-container>

<!-- expireDate Column -->
<ng-container class="col" matColumnDef="expireDate">
  <th [ngStyle]="{'display': visibility.expireDate ? '' : 'none' }" mat-header-cell *matHeaderCellDef mat-sort-header> Expire Date</th>
  <td [ngStyle]="{'display': visibility.expireDate ? '' : 'none' }" mat-cell *matCellDef="let row"> {{row.expireDate}} </td>
</ng-container>

<!-- activation Column -->
<ng-container class="col" matColumnDef="activation">
  <th [ngStyle]="{'display': visibility.activation ? '' : 'none' }" mat-header-cell *matHeaderCellDef mat-sort-header> Aktivasyon</th>
  <td [ngStyle]="{'display': visibility.activation ? '' : 'none' }" mat-cell *matCellDef="let row">
    <p *ngIf="row.activation" class="py-1  my-auto alert-success text-center rounded"
       style="width: 55px;"> Aktif</p>
    <p *ngIf="!row.activation" class="py-1  my-auto alert-danger text-center rounded"
       style="width: 55px;"> Pasif</p>
  </td>
</ng-container>

<!-- isActive Column -->
<ng-container class="col" matColumnDef="isActive" >
  <th [ngStyle]="{'display': visibility.isActive ? '' : 'none' }" mat-header-cell *matHeaderCellDef mat-sort-header> Aktif Mi?</th>
  <td [ngStyle]="{'display': visibility.isActive ? '' : 'none' }" mat-cell *matCellDef="let row">
    <mat-icon class="iconActive" *ngIf="row.isActive" style="color:#006400 ">check</mat-icon>
    <mat-icon class="iconActive" *ngIf="!row.isActive" style="color: #8b0000">close</mat-icon>
  </td>
</ng-container>

<!-- remainingTime Column -->
<ng-container class="col" matColumnDef="remainingTime">
  <th [ngStyle]="{'display': visibility.remainingTime ? '' : 'none' }" mat-header-cell *matHeaderCellDef mat-sort-header> Kalan Süre</th>
  <td [ngStyle]="{'display': visibility.remainingTime ? '' : 'none' }" mat-cell *matCellDef="let row"> {{row.remainingTime}} </td>
</ng-container>

<!-- licenseType Column -->

<ng-container class="col" matColumnDef="licenseType"  >
  <th  [ngStyle]="{'display': visibility.licenseType ? '' : 'none' }"  mat-header-cell *matHeaderCellDef mat-sort-header  > Lisans Tipi</th>
  <td  [ngStyle]="{'display': visibility.licenseType ? '' : 'none' }" mat-cell *matCellDef="let row"   ><p class="py-1  my-auto alert-success text-center rounded"
                                        style="width: 55px;"> {{row.licenseType}}</p></td>
</ng-container>


<!-- licenseStatus Column -->
<ng-container class="col" matColumnDef="licenseStatus">
  <th [ngStyle]="{'display': visibility.licenseStatus ? '' : 'none' }" mat-header-cell *matHeaderCellDef mat-sort-header> Lisans Durumu</th>
  <td [ngStyle]="{'display': visibility.licenseStatus ? '' : 'none' }" mat-cell *matCellDef="let row">
    <p *ngIf="row.licenseStatus === 'Demo' " class="py-1  my-auto alert-warning text-center rounded"
       style="width: 55px;"> {{row.licenseStatus}}</p>
    <p *ngIf="row.licenseStatus === 'Lisanslı'" class="py-1  my-auto alert-success text-center rounded"
       style="width: 55px;"> {{row.licenseStatus}}</p>
    <p *ngIf="row.licenseStatus === 'SüresiDolmuş'" class="py-1  my-auto alert-danger text-center rounded"
       style="width: 55px;"> {{row.licenseStatus}}</p></td>
</ng-container>

<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

<!-- Row shown when there is no matching data.
<tr class="mat-row" *matNoDataRow>
  <td class="mat-cell" colspan="4">Eşleşme bulunamadı "{{input.value}}"</td>
</tr>
-->
</table>

</div>
