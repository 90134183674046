export class AdminModel {
  id?: number;
  email?: string;
  password?: string;
  adminType?: number;
  name?: string;
  surname?: string;
  dealerCode?: string;
  phone?: string;
  isActive?: boolean;
}
