import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'analysis-customer-card',
  templateUrl: './analysis-customer-card.component.html',
  styleUrls: ['./analysis-customer-card.component.css']
})
export class AnalysisCustomerCardComponent implements OnInit {


  constructor() {
  }

  ngOnInit(): void {
  }

}


