import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AnalysisIndexComponent} from './analysis-index/analysis-index.component';
import {AnalysisRoutingModule} from "./analysis-routing.module";
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {MatBadgeModule} from '@angular/material/badge';
import {AnalysisCardComponent} from "../../components/analysis/analysis-card/analysis-card.component";
import {AnalysisOrderCardComponent} from "../../components/analysis/analysis-order-card/analysis-order-card.component";
import {AnalysisCustomerCardComponent} from "../../components/analysis/analysis-customer-card/analysis-customer-card.component";
import {AnalysisProductCardComponent} from "../../components/analysis/analysis-product-card/analysis-product-card.component";
import {MatTabsModule} from '@angular/material/tabs';
import {AnalysisPacketCardComponent} from "../../components/analysis/analysis-packet-card/analysis-packet-card.component";
import {ChartsModule, WavesModule} from 'angular-bootstrap-md';
import {AnalysisTableComponent} from "../../components/analysis/analysis-table/analysis-table.component";
import {NzProgressModule} from 'ng-zorro-antd/progress';
import {MatTableModule} from '@angular/material/table';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatInputModule} from "@angular/material/input";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatRippleModule} from "@angular/material/core";
import {LicencePacketChartComponent} from "../../components/analysis-chart/licence-packet-chart/licence-packet-chart.component";
import {DealerPacketChartComponent} from "../../components/analysis-chart/dealer-packet-chart/dealer-packet-chart.component";
import {SectorPacketChartComponent} from "../../components/analysis-chart/sector-packet-chart/sector-packet-chart.component";
import {RatePacketChartComponent} from "../../components/analysis-chart/rate-packet-chart/rate-packet-chart.component";
import {TableModule} from "primeng/table";
import {ButtonModule} from "primeng/button";
import {TagModule} from "primeng/tag";
import {CdkTableModule} from "@angular/cdk/table";
import {RippleModule} from "primeng/ripple";



@NgModule({
  declarations: [
    AnalysisIndexComponent,
    AnalysisCardComponent,
    AnalysisOrderCardComponent,
    AnalysisCustomerCardComponent,
    AnalysisProductCardComponent,
    AnalysisPacketCardComponent,
    AnalysisTableComponent,
    LicencePacketChartComponent,
    DealerPacketChartComponent,
    SectorPacketChartComponent,
    RatePacketChartComponent


  ],
  imports: [
    CommonModule,
    AnalysisRoutingModule,
    MatCardModule,
    MatButtonModule,
    MatBadgeModule,
    MatTabsModule,
    ChartsModule,
    WavesModule,
    NzProgressModule,
    MatTableModule,
    MatExpansionModule,
    MatInputModule,
    MatFormFieldModule,
    MatRippleModule,
    TableModule,
    ButtonModule,
    TagModule,
    CdkTableModule,
    RippleModule
  ]
})

export class AnalysisModule {
}
