import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'licence-packet-chart',
  templateUrl: './licence-packet-chart.component.html',
  styleUrls: ['./licence-packet-chart.component.sass']
})
export class LicencePacketChartComponent implements OnInit {


  //region licence chart code
  public ChartType: string = 'bar';

  public ChartDatasets: Array<any> = [
    {data: [65, 59, 80, 81, 56], label: 'Paket Sıralaması'}
  ];

  public ChartLabels: Array<any> = ['Tanışma Paketi-P1', 'Giriş Paketi-P2', 'Popüler Paketi-P3', 'Ultra Paket-P4', 'Sınırsız Paket-P5'];

  public ChartColors: Array<any> = [
    {
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)'

      ],
      borderColor: [
        'rgba(255,99,132,1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)'

      ],
      borderWidth: 2,
    }
  ];

  public ChartOptions: any = {
    responsive: true,


  };

  public ChartClicked(e: any): void {
  }

  public ChartHovered(e: any): void {
  }

  //endregion
  constructor() {
  }

  ngOnInit(): void {
  }

}
