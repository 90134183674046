import {Component, OnInit} from '@angular/core';
import {CompanyService} from "../../../core/service/company.service";

import {PaggingResponseModel} from "../../../core/models/pagging-response-model";
import {months} from "moment";
import {CompanyStatusEnum, CompanyStatusEnumTR} from "../../../enums/companyStatus-enum";


@Component({
  selector: 'analysis-index',
  templateUrl: './analysis-index.component.html',
  styleUrls: ['./analysis-index.component.css']
})
export class AnalysisIndexComponent implements OnInit {
  companies = [];
  page = new PaggingResponseModel();
  pageLimit = 50;
  selectedCard = 1;
  customers: Array<number>[];
  onemonth: Array<number>[];
  companyCount = {
    costumerCount: 0,
    oneMonthCount: 0,
    oneWeekCount: 0,
    expiredCount: 0,
    follewedByCount: 0,
    activeDemoCount: 0

  };
  today = new Date();
  constToday = new Date();
  todayMatch = true;
  emailActivation = 0;
  signedUpCount = 0;
  demoCount = 0;
  dataSource = [];
  constructor(
    private companyService: CompanyService) {
  }

  ngOnInit(): void {
    this.getPageData();
    this.whoSignedUpOnThisDate();
  }


  whoSignedUpOnThisDate = () => {
    this.emailActivation = 0 ;
    this.demoCount = 0 ;
    this.companyService.whoSignedUpOnThisDate(this.today.toDateString()).subscribe((res) => {
      res.items.forEach((f) => {
        if (f.emailActivation){
          this.emailActivation++; }
        if (f.companyStatus === 1){
          this.demoCount++; }
      });
      this.dataSource = res.items;
      this.signedUpCount = res.totalCount;

    });
  }
  companyStatusName = (status) => CompanyStatusEnumTR[CompanyStatusEnum[status]];

  dateMineus = () => {
    this.today.setDate(this.today.getDate() - 1);
    this.todayMatch = false;
    this.whoSignedUpOnThisDate();
  }

  dateplus = () => {
    this.today.setDate(this.today.getDate() + 1);
    this.todayMatch = true;
    if (this.today.getFullYear() <= this.constToday.getFullYear())
    {
      if (this.today.getMonth() <= this.constToday.getMonth()) {
        if (this.today.getDate() + 1 <= this.constToday.getDate() || this.today.getMonth() < this.constToday.getMonth()) {
          this.todayMatch = false;
        }
      }
    }
    this.whoSignedUpOnThisDate();
  }



  selectCard(id: number): any {
    this.selectedCard = id;
    console.log(this.selectedCard);
  }

  getPageData() {
    this.companyService.whoSignedUpOnThisDate("2022-11-05T18:44:36.083483").subscribe((res) => {

      console.warn(res);
    });

    this.companyService.companyAnalysisList()
      .subscribe((res: any) => {
        console.log(res);
        if (res.status === true) {
          this.companyCount.costumerCount = res.data.companyCount;
          this.companyCount.expiredCount = res.data.expired;
          this.companyCount.activeDemoCount = res.data.activesInDemo;
          this.companyCount.follewedByCount = res.data.followedBy;
          this.companyCount.oneWeekCount = res.data.oneWeekLeft;
          this.companyCount.oneMonthCount = res.data.oneMonthLeft;

        }
      });
  }




  getCompanies(page) {
    this.companyService.companyList({
      Page: page,
      PageSize: this.pageLimit
    }).subscribe((response: any) => {
      this.companies = response.data;
      this.customers = response.data.length;
      this.companyCount.costumerCount = response.data.length;
      console.log(response.data);
      console.log(this.customers);
      console.log(this.companyCount.costumerCount);
    });
  }
}
