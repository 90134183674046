<nav #navbar class="navbar" [ngClass]="isNavbarShow ? 'active' : 'inactive'">
  <div class="container-fluid" style="display: block !important;">
    <div class="navbar-header">
      <a href="#" onClick="return false;" class="navbar-toggle collapsed"
         (click)="isNavbarCollapsed = !isNavbarCollapsed" aria-expanded="false"></a>
      <!-- mobile menu bars -->
      <a href="#" onClick="return false;" class="bars" (click)="mobileMenuSidebarOpen($event,'overlay-open')"></a>
      <a class="navbar-brand" routerLink="analiz/analiz-anasayfa">
        <img src="assets/images/magenty-logo.png" width="35" alt="" />
        <span class="logo-name">Magenty</span>
      </a>
    </div>

    <div class="collapse navbar-collapse" [ngbCollapse]="isNavbarCollapsed">
      <ul class="pull-left collapse-menu-icon">

        <li>
          <button mat-icon-button (click)="callSidemenuCollapse()" class="sidemenu-collapse">
            <mat-icon>menu</mat-icon>
          </button>
        </li>
      </ul>
      <ul class="nav navbar-nav navbar-right">
<li>
  {{appVersion}}
</li>
        <!-- Full Screen Button -->
        <li class="fullscreen">
          <button mat-icon-button (click)="callFullscreen()" class=" nav-notification-icons">
            <mat-icon>fullscreen</mat-icon>
          </button>
        </li>
        <!-- #END# Full Screen Button -->
<!--        <li class="nav-item" ngbDropdown>-->
<!--          <a ngbDropdownToggle class="lang-dropdown">-->
<!--            <img *ngIf="flagvalue !== undefined" src="{{flagvalue}}" height="16">-->
<!--            <img *ngIf="flagvalue === undefined" src="{{defaultFlag}}" height="16">-->
<!--          </a>-->
<!--          <div ngbDropdownMenu class="dropdown-menu lang-item dropdown-menu-right pullDown">-->
<!--            <a href="javascript:void(0);" class="dropdown-item lang-item-list" *ngFor="let item of listLang"-->
<!--               (click)="setLanguage(item.text, item.lang, item.flag)"-->
<!--               [ngClass]="{'active': langStoreValue === item.lang}">-->
<!--              <img src="{{item.flag}}" class="flag-img" height="12"> <span class="align-middle">{{item.text}}</span>-->
<!--            </a>-->
<!--          </div>-->
<!--        </li>-->
        <!-- #START# Notifications-->
        <li class="nav-item" ngbDropdown>
          <button mat-icon-button ngbDropdownToggle class="nav-notification-icons">
            <mat-icon>notifications_none</mat-icon>
            <span class="label-count bg-orange"></span>
          </button>
          <div ngbDropdownMenu class="notification-dropdown pullDown">
            <div class="noti-list" style="position: relative; max-width: 600px; max-height: 250px;" [perfectScrollbar]>
              <ul class="menu">
                <li>
                  <a href="#" *ngFor="let notification of notifications" onClick="return false;">
                    <span class="table-img msg-user">
                      <img src="{{notification.userImg}}" alt="">
                    </span>
                    <span class="menu-info">
                      <span class="menu-title">{{notification.userName}}</span>
                      <span class="menu-desc">
                        <i class="material-icons">access_time</i> {{notification.time}}
                      </span>
                      <span class="menu-desc">{{notification.message}}</span>
                    </span>
                  </a>
                </li>
              </ul>
            </div>
            <a class="text-center d-block border-top py-1" href="#" onClick="return false;">Read
              All Notifications</a>
          </div>
        </li>
        <!-- #END# Notifications-->

        <li class="nav-item user_profile" ngbDropdown>
          <a href="#" onClick="return false;" ngbDropdownToggle role="button" class="nav-notification-icons pt-0">
            <span class="inline-flex align-items-center bg-blue-50 px-3 py-2 mr-2 mb-2 lg:mb-0 border-1 border-blue-200"
                  style="border-radius: 50px;"><i class="pi pi-user text-blue-500 mr-2"></i><span
              class="text-gray-700 font-medium">{{fullname}}</span></span>
          </a>
          <div ngbDropdownMenu class="notification-dropdown pullDown">
            <div class="noti-list" style="position: relative; max-width: 600px; max-height: 300px;" [perfectScrollbar]>
              <ul class="menu">
                <li class="body">
                  <ul class="user_dw_menu">
                    <li>
                      <a href="#" onClick="return false;">
                        <mat-icon aria-hidden="false" class="mr-2">person</mat-icon>Profile
                      </a>
                    </li>
                    <li>
                      <a href="#" onClick="return false;">
                        <mat-icon aria-hidden="false" class="mr-2">feedback</mat-icon>Feedback
                      </a>
                    </li>
                    <li>
                      <a href="#" onClick="return false;">
                        <mat-icon aria-hidden="false" class="mr-2">help</mat-icon>Help
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);" (click)="logout()">
                        <mat-icon aria-hidden="false" class="mr-2">power_settings_new</mat-icon>Logout
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </li>
<!--         #END# Tasks-->
<!--        <li class="pull-right">-->
<!--          <button mat-icon-button (click)="toggleRightSidebar()" class="nav-notification-icons">-->
<!--            <mat-icon id="settingBtn">settings</mat-icon>-->
<!--          </button>-->
<!--        </li>-->
      </ul>
    </div>
  </div>
</nav>
