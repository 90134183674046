import {Component, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {any} from "codelyzer/util/function";
import {SelectionModel} from "@angular/cdk/collections";
import {Company} from "../../../core/models/company";
import { Output, EventEmitter } from '@angular/core';
import {TableColumnVisibility} from "../../../core/models/table-column-visibility";

@Component({
  selector: 'app-customer-table',
  templateUrl: './customer-table.component.html',
  styleUrls: ['./customer-table.component.sass']
})
export class CustomerTableComponent implements OnInit {

  @Input() dataSource: any;
  @Input() displayedColumns: string[];
  @Output() newItemEvent = new EventEmitter<SelectionModel<any>>();
  @Input() visibility: any;
  company: any;
  companyId: any;

  initialSelection = [];

  allowMultiSelect = true;
  selection = new SelectionModel<Company>(this.allowMultiSelect, this.initialSelection);

  constructor( private router: Router) { }

  ngOnInit(): void {


  }

  // tslint:disable-next-line:use-lifecycle-interface

  selectedChange()
  {
    this.newItemEvent.emit(this.selection);

  }



  editCompany(company) {
    this.company = company;
    this.companyId = company.id;
    console.log(this.company);
    this.router.navigate(['musteri/musteri-detay/' + this.companyId], { queryParams: this.companyId });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;


  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?

      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }
}
