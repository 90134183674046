import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminsManagementComponent } from './admins-management/admins-management.component';
import { AdminsManagementRoutingModule } from './admins-management-routing.module';
import { AdminsTableComponent } from 'src/app/components/admins-management/admins-table/admins-table.component';
import { AdminsDetailComponent } from 'src/app/components/admins-management/admins-detail/admins-detail.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTableModule } from '@angular/material/table';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatCardModule } from '@angular/material/card';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatStepperModule } from '@angular/material/stepper';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ShContextMenuModule } from 'ng2-right-click-menu';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import {GeneralTableComponent} from "../../components/general-table/general-table/general-table.component";
import {ToolbarModule} from "primeng/toolbar";
import {ButtonModule} from "primeng/button";
import {InputTextModule} from "primeng/inputtext";
import {RippleModule} from "primeng/ripple";
import {MultiSelectModule} from "primeng/multiselect";
import {TooltipModule} from "primeng/tooltip";
import {ConfirmPopupModule} from "primeng/confirmpopup";
import {AdminsFilterComponent} from "../../components/admins-management/admins-filter/admins-filter.component";
import {DropdownModule} from "primeng/dropdown";
import {InputMaskModule} from "primeng/inputmask";
import {TagModule} from "primeng/tag";
import {CarouselModule} from "primeng/carousel";
import {TuiButtonModule, TuiHostedDropdownModule} from "@taiga-ui/core";
import {TuiIslandModule, TuiLineClampModule, TuiPdfViewerModule} from "@taiga-ui/kit";

@NgModule({
    declarations: [
        AdminsManagementComponent,
        AdminsTableComponent,
        AdminsDetailComponent,
        GeneralTableComponent,
        AdminsFilterComponent
    ],
    exports: [
        GeneralTableComponent
    ],
    imports: [
        AdminsManagementRoutingModule,
        CommonModule,
        NgxDatatableModule,
        MatMenuModule,
        MatTooltipModule,
        MatTabsModule,
        ReactiveFormsModule,
        FormsModule,
        MatFormFieldModule,
        MatDialogModule,
        MatButtonModule,
        MatCardModule,
        MatSlideToggleModule,
        MatInputModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatIconModule,
        NgxDatatableModule,
        NzSelectModule,
        FormsModule,
        ShContextMenuModule,
        MatMenuModule,
        MatTooltipModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        MatStepperModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        DragDropModule,
        CKEditorModule,
        MatCardModule,
        NgxSpinnerModule,
        MatDialogModule,
        MatPaginatorModule,
        MatSnackBarModule,
        MatTableModule,
        MatDatepickerModule,
        MatSelectModule,
        ToolbarModule,
        ButtonModule,
        InputTextModule,
        RippleModule,
        MultiSelectModule,
        TooltipModule,
        ConfirmPopupModule,
        DropdownModule,
        InputMaskModule,
        TagModule,
        CarouselModule,
        TuiButtonModule,
        TuiIslandModule,
        TuiLineClampModule,
        TuiPdfViewerModule,
        TuiHostedDropdownModule
    ]
})
export class AdminsManagementModule { }
