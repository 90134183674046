import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sector-packet-chart',
  templateUrl: './sector-packet-chart.component.html',
  styleUrls: ['./sector-packet-chart.component.sass']
})
export class SectorPacketChartComponent implements OnInit {

  //region sector chart code
  public ChartType: string = 'bar';

  public ChartDatasets: Array<any> = [
    { data: [65, 59, 72, 85, 56, 55, 40], label: 'Sektörel Oran' }
  ];

  public ChartLabels: Array<any> = ['Ev ve Yaşam', 'Ayakkabı', 'Giyim', 'Elektronik', 'Spor&Outdoor', 'Kozmetik'];

  public ChartColors: Array<any> = [
    {
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)'
      ],
      borderColor: [
        'rgba(255,99,132,1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)'
      ],
      borderWidth: 2,
    }
  ];

  public ChartOptions: any = {
    responsive: true
  };
  public ChartClicked(e: any): void { }
  public ChartHovered(e: any): void { }
  //endregion

  constructor() { }

  ngOnInit(): void {
  }

}
