import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {CustomerManagementIndexComponent} from "./customer-management-index/customer-management-index.component";
import {CustomerDetailComponent} from "../../components/customer-management/customer-detail/customer-detail.component";

const routes: Routes = [
  {
    path: '',
    redirectTo: 'musteri-yonetimi',
    pathMatch: 'full'
  },
  {
    path: 'musteri-yonetimi/:list_type',
    component: CustomerManagementIndexComponent
  },
  {
    path: 'musteri-detay/:id',
    component: CustomerDetailComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CustomerManagementRoutingModule { }
