export class PaggingResponseModel {
  Items: any [];
  Status: boolean;
  Message: string;
  TotalCount: number;
  Page: number;
  PageSize: number;
  Exception: any;
  ProviderProducts?: any [];
}
