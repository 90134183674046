import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, of, empty} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Admin} from "../models/admin";
import {BaseResponse} from "../models/base-response";
import {LoginResponse} from "../models/login-response";
import {SelectAdminResponse} from "../models/select-admin-response";
import {environment} from 'src/environments/environment';
import {RegisterRequest} from "../models/register-request";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<string>;
  public currentUser: Observable<string>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<string>(
      localStorage.getItem('currentUser')
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): string {
    return this.currentUserSubject.value;
  }

  register = (request: RegisterRequest) => {
    return this.http
      .post(`${environment.localApiUrl}/api/client/register`, request)
      .pipe(map((res: any) => {
        return res;
      }));
  };

  login(email: string, password: string) {
    console.log(email, password);
    return this.http
      .post<any>(`${environment.localApiUrl}/api/client/login`, {
        Email: email,
        Password: password
      })
      .pipe(
        map((res: any) => {

          const selectAdminResponse: any = res.token;
          localStorage.setItem('currentUser', `Bearer ${selectAdminResponse}`);
          this.currentUserSubject.next(selectAdminResponse);
          return res;
        }),
      );
  }

  logout() {
    // remove user from local storage to log user out
    return this.http
      .get(`${environment.localApiUrl}/api/client/logout`)
      .pipe(
        map((res: any) => {
          localStorage.removeItem('currentUser');
          this.currentUserSubject.next(null);
          return of({success: false});
        })
      );
  }

}
