import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerManagementIndexComponent } from './customer-management-index/customer-management-index.component';
import {CustomerManagementRoutingModule} from "./customer-management-routing.module";
import {MatMenuModule} from "@angular/material/menu";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatTabsModule} from "@angular/material/tabs";
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatButtonModule} from "@angular/material/button";
import {MatCardModule} from "@angular/material/card";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatInputModule} from "@angular/material/input";
import {MatChipsModule} from "@angular/material/chips";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatIconModule} from "@angular/material/icon";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {MatDialogModule} from "@angular/material/dialog";
import {NzSelectModule} from "ng-zorro-antd/select";
import {ShContextMenuModule} from "ng2-right-click-menu";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatStepperModule} from "@angular/material/stepper";
import {CKEditorModule} from "@ckeditor/ckeditor5-angular";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {NgxSpinnerModule} from "ngx-spinner";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatTableModule} from '@angular/material/table';
import {CustomerDetailComponent} from "../../components/customer-management/customer-detail/customer-detail.component";
import {MatDatepickerModule} from '@angular/material/datepicker';
import {AppModule} from "../../app.module";
import {CustomerTableComponent} from "../../components/customer-management/customer-table/customer-table.component";
import {MatSelectModule} from "@angular/material/select";
import {AdminsManagementModule} from "../admins-management/admins-management.module";
import {InputTextModule} from "primeng/inputtext";
import {InputTextareaModule} from "primeng/inputtextarea";
import {CalendarModule} from "primeng/calendar";
import {ToggleButtonModule} from "primeng/togglebutton";
import {InputSwitchModule} from "primeng/inputswitch";
import {InputNumberModule} from "primeng/inputnumber";
import {InputMaskModule} from "primeng/inputmask";
import {CustomerInformationComponent} from "../../components/customer-management/customer-information/customer-information.component";
import {ToolbarModule} from "primeng/toolbar";


@NgModule({
  declarations: [
    CustomerManagementIndexComponent,
    CustomerDetailComponent,
    CustomerTableComponent,
    CustomerInformationComponent
  ],
    imports: [
        CommonModule,
        CustomerManagementRoutingModule,
        NgxDatatableModule,
        MatMenuModule,
        MatTooltipModule,
        MatTabsModule,
        ReactiveFormsModule,
        FormsModule,
        MatFormFieldModule,
        MatDialogModule,
        MatButtonModule,
        MatCardModule,
        MatSlideToggleModule,
        MatInputModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatIconModule,
        NgxDatatableModule,
        NzSelectModule,
        FormsModule,
        ShContextMenuModule,
        MatMenuModule,
        MatTooltipModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        MatStepperModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        DragDropModule,
        CKEditorModule,
        MatCardModule,
        NgxSpinnerModule,
        MatDialogModule,
        MatPaginatorModule,
        MatSnackBarModule,
        MatTableModule,
        MatDatepickerModule,
        MatSelectModule,
        AdminsManagementModule,
        InputTextModule,
        InputTextareaModule,
        CalendarModule,
        ToggleButtonModule,
        InputSwitchModule,
        InputNumberModule,
        InputMaskModule,
        ToolbarModule
    ]
})
export class CustomerManagementModule { }
