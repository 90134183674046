import {Component, OnInit} from '@angular/core';

export interface PeriodicElement {
  companyName: string;
  authorizedPerson: string;
  startDate: string;
  endDate: string;
  activation: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
    companyName: 'Tekviya Yazılım ve Bilgi Teknolojileri',
    authorizedPerson: 'Yusuf Küçük',
    startDate: ' 01.01.2018',
    endDate: '01.01.2080',
    activation: 'aktif'
  },
  {
    companyName: ' Test Firma 1',
    authorizedPerson: 'Deneme 1',
    startDate: '01.01.2000',
    endDate: '01.01.2080',
    activation: 'pasif'
  },
  {
    companyName: 'Test Firma 2',
    authorizedPerson: 'Deneme 2',
    startDate: '01.01.2000',
    endDate: '01.01.2080',
    activation: 'aktif'
  },
  {
    companyName: 'Test Firma 3',
    authorizedPerson: 'Deneme 3',
    startDate: '01.01.2000',
    endDate: '01.01.2080',
    activation: 'pasif'
  },
  {
    companyName: 'Test Firma 4',
    authorizedPerson: 'Deneme 4',
    startDate: '01.01.2000',
    endDate: '01.01.2080',
    activation: 'aktif'
  },
  {
    companyName: 'Test Firma 5',
    authorizedPerson: 'Deneme 5',
    startDate: '01.01.2000',
    endDate: '01.01.2080',
    activation: 'pasif'
  },

];

@Component({
  selector: 'analysis-table',
  templateUrl: './analysis-table.component.html',
  styleUrls: ['./analysis-table.component.css']
})


export class AnalysisTableComponent implements OnInit {


  displayedColumns: string[] = ['companyName', 'authorizedPerson', 'startDate', 'endDate', 'activation'];
  dataSource = ELEMENT_DATA;

  constructor() {
  }

  ngOnInit()
    :
    void {
  }

}

