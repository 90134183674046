import { Component, OnInit } from '@angular/core';
import {AdminFilterModel} from "../../../Models/admin-filter-model";
import {OrderFilterModel} from "../../../Models/order-filter-model";
import {DynamicDialogRef} from "primeng/dynamicdialog";

@Component({
  selector: 'app-admins-filter',
  templateUrl: './admins-filter.component.html',
  styleUrls: ['./admins-filter.component.sass']
})
export class AdminsFilterComponent implements OnInit {
  admin: AdminFilterModel = new AdminFilterModel();
  constructor(public ref: DynamicDialogRef) { }
  adminType = [
    {name: 'Admin', code: 0},
  ];
  isActive = [
    {name: 'Aktiflik', code: 0},
    {name: 'Aktif', code: 1},
    {name: 'Pasif', code: 2},
  ];
  ngOnInit(): void {
    this.admin = {
      adminType: 99,
      email: "",
      isActive: 0,
      name: "",
      phone: "",
      surname: ""
    };
  }

  saveFilter = () => {
    const defaultFilter: AdminFilterModel = {
      adminType: 99,
      email: "",
      isActive: 0,
      name: "",
      phone: "",
      surname: ""
    };
    if (JSON.stringify(this.admin) === JSON.stringify(defaultFilter))
    {
      this.ref.close(false);
    }
    else
    {
      console.log(this.admin);
      this.ref.close(this.admin);
    }
  }

}
