import {Component, Input, OnInit} from '@angular/core';
import {CompanyModel} from "../../../Models/company-model";
import {CompanyService} from "../../../core/service/company.service";
import {DialogService} from "primeng/dynamicdialog";
import {InformationDialogComponent} from "../../helper-components/information-dialog/information-dialog.component";

@Component({
  selector: 'app-customer-information',
  templateUrl: './customer-information.component.html',
  styleUrls: ['./customer-information.component.sass']
})
export class CustomerInformationComponent implements OnInit {
  @Input() company: CompanyModel = new  CompanyModel();
  constructor(private companyService: CompanyService,
              public dialogService: DialogService) { }

  ngOnInit(): void {
  }

  updateCompany() {

    this.companyService.updateCompany(this.company)
      .subscribe((res: any) => {
        console.log(res);
        if(res.status)
        {
        this.dialogService.open(InformationDialogComponent, {
          data: {
            text: 'Kullanıcı başarı ile güncellendi',
          },
          header: "Bilgi",
        });
        }
      });
  }

}
