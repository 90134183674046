
<div class="p-fluid grid mt-4 card p-4" >


<div class="row ">
  <div class="col">
   <span class=" p-float-label">
  <input [(ngModel)]="company.title" type="text"
         id="title" name="title" pInputText >
  <label for="title">Firma Adı</label>
    </span>
  </div>
</div>
  <div class="row  mt-4">
    <div class="col-12 col-md-6  ">
 <span class=" p-float-label">
    <input [(ngModel)]="company.name" type="text"
           id="name" name="name" pInputText >
  <label for="name">Ad</label>
    </span>
    </div>
    <div class="col-12 col-md-6 ">
  <span class=" p-float-label">
    <input [(ngModel)]="company.surname" type="text"
           id="surname" name="surname" pInputText >
  <label for="name">Soyad</label>
    </span>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-md-6 mt-4 ">
  <span class=" p-float-label">
  <input [(ngModel)]="company.email" type="text"
         id="email" name="email" pInputText >
  <label for="email">Email</label>
    </span>
    </div>
    <div class="col-12 col-md-6 mt-4">
  <span class=" p-float-label">
    <input [(ngModel)]="company.province" type="text"
           id="province" name="province" pInputText >
  <label for="province">İl</label>
    </span>
    </div>
  </div>



  <div class="row">
    <div class="col-12 col-md-6 mt-4 ">
  <span class=" p-float-label">
  <p-dropdown [options]="companyStatus" [(ngModel)]="company.companyStatus" optionLabel="name" optionValue="code" ></p-dropdown>

    </span>
    </div>
    <div class="col-12 col-md-6 mt-4  ">
  <span class=" p-float-label">
   <p-dropdown [options]="licenseType" [(ngModel)]="company.licenseType" optionLabel="name" optionValue="code" ></p-dropdown>

    </span>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-md-6 mt-4 ">
  <span class=" p-float-label">
  <p-dropdown [options]="isActive" [(ngModel)]="company.isActive" optionLabel="name" optionValue="code" ></p-dropdown>

    </span>
    </div>
    <div class="col-12 col-md-6 mt-4  ">
  <span class=" p-float-label">
   <p-dropdown [options]="listType" [(ngModel)]="company.listType" optionLabel="name" optionValue="code" ></p-dropdown>

    </span>
    </div>
  </div>

  <!--  <div class="row">-->
  <!--    <div class="col-12 col-md-6 mt-4 ">-->
  <!--  <span class=" p-float-label">-->
  <!--  <p-dropdown [options]="isYearly" [(ngModel)]="order.isYearly" optionLabel="name" optionValue="code" ></p-dropdown>-->

  <!--    </span>-->
  <!--    </div>-->
  <!--    <div class="col-12 col-md-6 mt-4  ">-->
  <!--  <span class=" p-float-label">-->


  <!--    </span>-->
  <!--    </div>-->
  <!--  </div>-->

  <button pButton type="button" class="mt-5" (click)="saveFilter()" label="Ara" ></button>



</div>


<!--<div class="card">-->

<!--  <div class="body">-->

<!--    <form [formGroup]="generalForm">-->
<!--      <div class="row">-->

<!--        &lt;!&ndash;  Left-side-Start&ndash;&gt;-->
<!--        <div class="column-6 mr-3">-->

<!--          &lt;!&ndash;  Lisans Tipi Start &ndash;&gt;-->
<!--          <mat-form-field class="example-full-width" appearance="outline">-->
<!--            <mat-label>Lisans Tipi</mat-label>-->
<!--            <mat-select formControlName="licenseType">-->
<!--              <mat-option value="0">Seçiniz</mat-option>-->
<!--              <mat-option value="1">Demo</mat-option>-->
<!--              <mat-option value="2">Lisanslı</mat-option>-->
<!--            </mat-select>-->
<!--          </mat-form-field>-->
<!--          &lt;!&ndash;  Lisans Tipi End &ndash;&gt;-->
<!--          <br>-->

<!--          &lt;!&ndash;  E-mail Start &ndash;&gt;-->
<!--          <mat-form-field class="example-full-width" appearance="outline">-->
<!--            <mat-label>E-mail</mat-label>-->
<!--            <input matInput formControlName="email">-->
<!--          </mat-form-field>-->
<!--          &lt;!&ndash;  E-mail End &ndash;&gt;-->
<!--          <br>-->
<!--          &lt;!&ndash;  Ünvan Start &ndash;&gt;-->
<!--          <mat-form-field class="example-full-width" appearance="outline">-->
<!--            <mat-label>Ünvan</mat-label>-->
<!--            <input matInput formControlName="fullname">-->
<!--          </mat-form-field>-->
<!--          &lt;!&ndash;  Ünvan End &ndash;&gt;-->
<!--          <br>-->
<!--          &lt;!&ndash;  il Start &ndash;&gt;-->
<!--          <mat-form-field class="example-full-width" appearance="outline">-->
<!--            <mat-label>İl</mat-label>-->
<!--            <input matInput formControlName="province">-->
<!--          </mat-form-field>-->
<!--          &lt;!&ndash;  il End &ndash;&gt;-->


<!--        </div>-->
<!--        &lt;!&ndash;  Left-side-End&ndash;&gt;-->

<!--        &lt;!&ndash;  right-side-start&ndash;&gt;-->
<!--        <div class="column-6 ">-->

<!--          &lt;!&ndash;  Firma Adı Start &ndash;&gt;-->
<!--          <mat-form-field class="example-full-width" appearance="outline">-->
<!--            <mat-label>Firma Adı</mat-label>-->
<!--            <input matInput formControlName="title">-->
<!--          </mat-form-field>-->
<!--          &lt;!&ndash;  Firma Adı End&ndash;&gt;-->

<!--          <br>-->
<!--          &lt;!&ndash;  Lisans Durumu Start &ndash;&gt;-->
<!--          <mat-form-field class="example-full-width" appearance="outline">-->
<!--            <mat-label>Lisans Durumu</mat-label>-->
<!--            <mat-select formControlName="companyStatus">-->
<!--              <mat-option value="0">Seçiniz</mat-option>-->
<!--              <mat-option value="1">Demo</mat-option>-->
<!--              <mat-option value="2">Lisanslı</mat-option>-->
<!--              <mat-option value="3">Süresi Dolmuş</mat-option>-->
<!--            </mat-select>-->
<!--          </mat-form-field>-->
<!--          &lt;!&ndash;  Lisans Durumu End&ndash;&gt;-->
<!--          <br>-->

<!--          &lt;!&ndash;  Lisans Tipi Start&ndash;&gt;-->
<!--          <mat-form-field class="example-full-width" appearance="outline">-->
<!--            <mat-label>Listeleme Tipi</mat-label>-->
<!--            <mat-select formControlName="listType">-->
<!--              <mat-option value="0">Seçiniz</mat-option>-->
<!--              <mat-option value="1">1 Ay Kalanlar</mat-option>-->
<!--              <mat-option value="2">1 Hafta Kalanlar</mat-option>-->
<!--              <mat-option value="3">Süresi Dolanlar</mat-option>-->
<!--              <mat-option value="4">Demo Aktifler</mat-option>-->
<!--            </mat-select>-->
<!--          </mat-form-field>-->
<!--          &lt;!&ndash;  Lisans Tipi End&ndash;&gt;-->
<!--          <br>-->
<!--          &lt;!&ndash;  Üyelik Durumu Start&ndash;&gt;-->
<!--          <mat-form-field class="example-full-width" appearance="outline">-->
<!--            <mat-label>Aktif mi?</mat-label>-->
<!--            <mat-select formControlName="isActive">-->
<!--              <mat-option value=0>Seçiniz</mat-option>-->
<!--              <mat-option value=1>Aktif</mat-option>-->
<!--              <mat-option value=2>Pasif</mat-option>-->

<!--            </mat-select>-->
<!--          </mat-form-field>-->


<!--          &lt;!&ndash;  Üyelik Durumu End&ndash;&gt;-->


<!--        </div>-->
<!--        &lt;!&ndash;  right-side-end&ndash;&gt;-->
<!--      </div>-->

<!--      <div class="row">-->

<!--        <button pButton type="button"  (click)="saveFilter()" label="Kaydet" ></button>-->



<!--      </div>-->
<!--    </form>-->
<!--  </div>-->

<!--</div>-->
